export const RenderTable = ({ fetchedData }) => {
    // Check if fetchedData is null or undefined
    if (fetchedData == null) {
      return <p className="text-gray-700">No data available</p>;
    }
  
    // Determine headers - use the first row if available, or an empty array if no data
    const headers = fetchedData.length > 0 ? Object.keys(fetchedData[0]) : [];
  
    return (
      <div className="overflow-auto max-w-full border rounded-md max-h-[63vh]">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className="sticky top-0 p-2 text-left bg-gray-100 z-[1]">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fetchedData.length > 0 ? (
              fetchedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, cellIndex) => (
                    <td key={cellIndex} className="border border-gray-300 p-2">{row[header]}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length} className="text-center p-2">No data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>      
    );
  };