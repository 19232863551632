import React, { useEffect } from 'react';
import * as d3 from 'd3';

const CreateXPlot = () => {
  useEffect(() => {
    const data1 = [
      { date: new Date(2008, 0, 1), value: 100 },
      { date: new Date(2008, 1, 1), value: 90 },
      { date: new Date(2008, 2, 1), value: 85 },
      { date: new Date(2008, 3, 1), value: 70 },
      { date: new Date(2008, 4, 1), value: 60 },
      { date: new Date(2008, 5, 1), value: 50 },
      { date: new Date(2008, 6, 1), value: 40 },
      { date: new Date(2008, 7, 1), value: 30 },
      { date: new Date(2008, 8, 1), value: 20 },
      { date: new Date(2008, 9, 1), value: 15 },
      { date: new Date(2008, 10, 1), value: 10 },
      { date: new Date(2008, 11, 1), value: 5 },
    ];

    const data2 = [
      { date: new Date(2008, 0, 1), value: 50 },
      { date: new Date(2008, 1, 1), value: 55 },
      { date: new Date(2008, 2, 1), value: 60 },
      { date: new Date(2008, 3, 1), value: 45 },
      { date: new Date(2008, 4, 1), value: 30 },
      { date: new Date(2008, 5, 1), value: 35 },
      { date: new Date(2008, 6, 1), value: 40 },
      { date: new Date(2008, 7, 1), value: 30 },
      { date: new Date(2008, 8, 1), value: 20 },
      { date: new Date(2008, 9, 1), value: 25 },
      { date: new Date(2008, 10, 1), value: 20 },
      { date: new Date(2008, 11, 1), value: 15 },
    ];

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 480 - margin.left - margin.right;
    const height = 240 - margin.top - margin.bottom;

    const svg = d3.select('#x-plot')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime()
      .domain(d3.extent(data1, d => d.date))
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([0, d3.max([...data1, ...data2], d => d.value)])
      .range([height, 0]);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append('g')
      .call(d3.axisLeft(y));

    svg.append('path')
      .datum(data1)
      .attr('fill', 'none')
      .attr('stroke', 'darkgray')
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .x(d => x(d.date))
        .y(d => y(d.value))
      );

    svg.append('path')
      .datum(data2)
      .attr('fill', 'none')
      .attr('stroke', 'purple')
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .x(d => x(d.date))
        .y(d => y(d.value))
      );

  }, []);

  return <div id="x-plot"></div>;
};

export default CreateXPlot;
