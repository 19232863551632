import React, { useEffect } from 'react';
import * as d3 from 'd3';

const CreateZPlot = () => {
  useEffect(() => {
    const generateData = (n, skew = 0) => {
      const data = [];
      for (let i = 0; i < n; i++) {
        const rand = d3.randomNormal()();
        data.push(rand + skew);
      }
      return data.sort((a, b) => a - b);
    };

    const dataLeft = generateData(1000, -2);
    const dataNormal = generateData(1000);
    const dataRight = generateData(1000, 2);

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 480 - margin.left - margin.right;
    const height = 240 - margin.top - margin.bottom;

    const svg = d3.select('#z-plot')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleLinear()
      .domain([d3.min(dataLeft), d3.max(dataRight)])
      .range([0, width]);

    const histogram = d3.histogram()
      .domain(x.domain())
      .thresholds(x.ticks(30));

    const binsLeft = histogram(dataLeft);
    const binsNormal = histogram(dataNormal);
    const binsRight = histogram(dataRight);

    const y = d3.scaleLinear()
      .domain([0, d3.max([...binsLeft, ...binsNormal, ...binsRight], d => d.length)])
      .range([height, 0]);

    const drawHistogram = (bins, color, offset) => {
      svg.append('g')
        .selectAll('rect')
        .data(bins)
        .enter()
        .append('rect')
        .attr('x', d => x(d.x0) + offset)
        .attr('y', d => y(d.length))
        .attr('width', x(bins[0].x1) - x(bins[0].x0) - 1)
        .attr('height', d => height - y(d.length))
        .attr('fill', color)
        .attr('opacity', 0.6);
    };

    drawHistogram(binsLeft, 'pink', -2);
    drawHistogram(binsNormal, 'blue', 0);
    drawHistogram(binsRight, 'green', 2);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append('g')
      .call(d3.axisLeft(y));

  }, []);

  return <div id="z-plot"></div>;
};

export default CreateZPlot;
