import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import './styles.css';
import logo from './trianglai-logo.svg';
import hero from './hero.svg';

import { analytics } from '../firebase';

const Dyno = () => {
    useEffect(() => {
      analytics.logEvent('d3_page_view', { page: 'd3' });
    }, []);
  
    const trackClick = (label) => {
      analytics.logEvent('d3_click', { button: label });
    };
  
    return (
    <div className="flex flex-col min-h-screen">
        {/* SEO: Title and Meta Description for better search visibility */}
        <Helmet>
            <title>One-click AI | TriangleAI</title>
            <meta name="description" content="Maximize ROI with triangl.ai | Use AI to turn data points into insights with TriangleAI. Business intelligence forecasting. Data-driven decision-making with Generative AI." />
        </Helmet>

        <header className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-3xl shadow-xl p-3 mt-3 mx-auto container max-w-6xl text-center">
            <nav className="flex justify-between items-center">
                <img src={logo} alt="TriangleAI | simple AI | triangl.ai | AI for Enterprise | AI for ROI Maximization" className="h-14 mr-2"/>
                <div className="flex justify-end items-center gap-4">                    
                    <a href="https://calendly.com/treebel/custom-ai-for-enterprise" target="_blank" rel="noopener noreferrer">
                        <button 
                        onClick={() => trackClick('header_CTA_clicked')}
                        className="text-black bg-yellow-400 hover:bg-yellow-500 transition ease-in-out duration-200 text-lg font-bold px-6 py-2 rounded-full border border-black">
                            Book Demo
                        </button>
                    </a>
                </div>
            </nav>
        </header>

        <main className="flex-grow mt-9 mx-auto max-w-6xl">
            <div className="px-6 py-12">
                <section className="flex flex-col md:flex-row items-center md:items-start mb-12">
                    <div className="md:w-1/2 text-left">
                        <h1 className="text-5xl font-bold leading-tight">Innovate with Expert AI & Data Science Consulting</h1>
                        <p className="text-xl mt-4 leading-relaxed">Experience the future of enterprise technology with TriangleAI. Our expert team provides personalized insights and solutions to meet your unique business challenges. These versions maintain the core message while adhering to the character limits.</p>
                        <a href="https://calendly.com/treebel/custom-ai-for-enterprise" target="_blank" rel="noopener noreferrer">
                            <button 
                            onClick={() => trackClick('hero_CTA_clicked')}
                            className="mt-6 px-8 py-4 bg-yellow-400 text-black text-lg font-bold rounded-full hover:bg-yellow-500 transition ease-in-out duration-200">
                                
                                Book Demo
                            </button>
                        </a>
                    </div>
                    <div className="md:w-1/2 mt-8 md:mt-0">
                        <img src={hero} alt="Image Description" className="mx-auto"/>
                    </div>
                </section>
            </div>
        </main>
        <footer className="py-3">
            <div className="container mx-auto px-1 text-center">
                <div className="logo font-bold text-2xl">© {new Date().getFullYear()} TriangleAI, Inc.</div>
                <span>All rights reserved.</span>
            </div>
        </footer>
    </div>
);
};
export default Dyno;