import React, { useState, useEffect, useRef } from 'react';
import AppLayout from './AppLayout';
import { FaChartLine, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { TbChartBubbleFilled } from "react-icons/tb";
import { TbRowRemove } from "react-icons/tb";
import { PiSparkle } from "react-icons/pi";
import { FaCodeBranch } from "react-icons/fa6";
import Loading from './Loading'; 

// d3
import Heatmap from "./Heat";
import Line from "./Line";
import IForest from './IsolatedForest';

// GA
import { analytics } from '../firebase';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const Notebook = ({ username, isPremiumUser  }) => {
  const [title, setTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [objects, setObjects] = useState([]);
  const [meta, setMeta] = useState([]);
  const [isHoveredA, setIsHoveredA] = useState(false);
  const [isHoveredB, setIsHoveredB] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [showAddContainer, setShowAddContainer] = useState(false);
  const addContainerRef = useRef();
  const [showQueryContainer, setShowQueryContainer] = useState(false);
  const [queryType, setQueryType] = useState('');
  const [queryText, setQueryText] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [features, setFeatures] = useState([]);
  const [activeFeature, setActiveFeatures] = useState([]);

  const { notebookId } = useParams(); // Access route parameters

  // GA - Start // 
  useEffect(() => {
    analytics.logEvent('notebook_page', { page: 'notebook' });
  }, []);
  // GA - END //

  const multiLists = ['Correlation', 'Anomaly'];

  const handleColumnClick = (column) => {
    analytics.logEvent(`feature_${column}`, { page: 'notebook' });
    if (multiLists.includes(queryType)) {
      // Multi-selection logic
      setActiveFeatures(prevFeatures => {
        if (prevFeatures.includes(column)) {
          return prevFeatures.filter(feature => feature !== column); // Deselect if already selected
        } else {
          return [...prevFeatures, column]; // Add to selection if not already selected
        }
      });
    } else {
      // Single-selection logic
      setActiveFeatures(prevFeatures => {
        return prevFeatures.includes(column) ? [] : [column]; // Toggle selection
      });
    }
  };
  
  const handleFileSelection = (e) => {
    setSelectedFile(e.target.value);
  };

  async function deleteBlock(blockId) {
    analytics.logEvent('delete_block', { page: 'notebook' });
    const response = await fetch(`${BASE_URL}/del`, { 
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId: username.multiFactor.user.uid,
        notebookId: notebookId,
        blockId: blockId,
      }),
    });
  
    if (!response.ok) {
      throw new Error(`Error: ${response.status} - ${response.statusText}`);
    }
  
    const data = await response.json();
    fetchPageData();
  }

  async function fetchBlock(endpoint) {
    const payload = {
      userId: username.multiFactor.user.uid,
      notebookId: notebookId,
      queryType: queryType,
      queryText: queryText,
      selectedFile: selectedFile,
      activeFeature: activeFeature,
    };

    try {
      const response = await fetch(`${BASE_URL}/${endpoint}`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Pass the payload as the request body
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }
      fetchPageData();
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  const handleSelection = async (selection) => {
    analytics.logEvent(`select_${selection}`, { page: 'notebook' });
    setShowAddContainer(false);
    setQueryType(selection);
    setShowQueryContainer(true);
  };

  const handleSubmit = async () => {
    // Create a placeholder object with a unique ID and loading indicators
    const placeholderId = Date.now(); // This serves as a temporary unique ID
    const loadingPlaceholder = {
      id: placeholderId,
      title: 'Building insights', // Placeholder title
      summary: <><li>Cleaning Data</li><li>Preparing Analysis</li><li>Testing Results</li><li>Summarizing Analysis</li></>,
      chart: 'loading', // Indicator to render the Loading component
    };
  
    // Immediately add the placeholder to the UI
    setObjects(prevObjects => [...prevObjects, loadingPlaceholder]);
  
    // Proceed to fetch the data
    const data = await fetchBlock(queryType);
    if (data) {
      // Replace the placeholder with the actual data
      setObjects(prevObjects =>
        prevObjects.map(obj =>
          obj.id === placeholderId ? { ...data, title: `Data for ${queryType.toUpperCase()}` } : obj
        )
      );
    }
  };  

  // Close the add container when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addContainerRef.current && !addContainerRef.current.contains(event.target)) {
        setShowAddContainer(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [addContainerRef]);

  // Fetch page payload from API
  const fetchPageData = async () => {
    setIsLoading(true); // Start loading
    try {
      const requestBody = {
        userId: username.multiFactor.user.uid,
        notebookId: notebookId,
        // Include any other relevant data for the POST request here
      };

      const response = await fetch(`${BASE_URL}/notebook-load`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      setTitle(data.title || 'Untitled Journal');
      setObjects(data.objects || []);
      setMeta(data || []);
    } catch (error) {
      console.error("Failed to fetch page data:", error);
      setTitle('Untitled Journal');
      setObjects([]);
    } finally {
      setIsLoading(false); // Stop loading regardless of outcome
    }
  };

  useEffect(() => {
  fetchPageData();
}, []);

useEffect(() => {
  if (Array.isArray(meta.data) && meta.data.length > 0) {
    setSelectedFile(meta.data[0]);
  } else if (meta.data) { 
    setSelectedFile(meta.data);
  }
}, [meta.data]); 

useEffect(() => {
  const fetchFeaturesData = async () => {
    if (!selectedFile) {
      console.log('No selected file to fetch features for.');
      return;
    }

    try {
      const payload = {
        userId: username.multiFactor.user.uid,
        selectedFile: selectedFile,
        queryType: queryType,
      };

      const response = await fetch(`${BASE_URL}/features`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const featuresData = await response.json();

      // Check if cols is a string and parse it if necessary
      if (featuresData.cols && typeof featuresData.cols === 'string') {
        try {
          // Attempt to parse cols as JSON after replacing single quotes with double quotes
          const parsedCols = JSON.parse(featuresData.cols.replace(/'/g, '"'));
          setFeatures(parsedCols); // Update state with parsed columns
        } catch (error) {
          console.error('Error parsing cols:', error);
          setFeatures([]); // Reset or handle parsing error
        }
      } else {
        setFeatures(featuresData.cols || []); // Directly set cols if it's already in the correct format
      }
    } catch (error) {
      console.error('Failed to fetch features:', error);
      setFeatures([]); // Optionally reset features to an empty array or handle the error differently
    }
  };

  fetchFeaturesData();
}, [selectedFile, queryType]); 

useEffect(() => {
  // Check if there are any features to select
  if (features.length > 0) {
    setActiveFeatures([features[0]]); // Select the first feature by default
  } else {
    setActiveFeatures([]); // Clear selection if there are no features
  }
}, [queryType, features]);

// Conditional rendering based on isLoading
if (isLoading) {
  return <Loading/>; // Render loading screen or spinner here
}

const renderObjects = () => {
  return (
    <div className="grid grid-cols-2 gap-8">
      {objects.map((obj, index) => {
        // Determine what to render based on obj.chart
        const renderChart = () => {
          if (obj.chart === 'loading') {
            // If the object is in loading state, show the Loading component
            return <Loading />;
          }
          // Handle actual chart types
          switch (obj.chart) {
            case 'Correlation':
              return <Heatmap chartData={obj.chart_data} x_axis={obj.chart_x} y_axis={obj.chart_y} />;
            case 'Anomaly':
              return <IForest chartData={obj.chart_data} x_axis={obj.chart_x} y_axis={obj.chart_y} />;
            case 'Forecast':
              return <Line data={obj.chart_data} />;
            default:
              // Handle other chart types or not render anything for unmatched types
              return null;
          }
        };
        return (
          <div key={index} className="group col-span-4 relative p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out max-w-[73%]" style={{ height: 'auto' }}>
            <h2 className="text-xl font-semibold mb-4">{obj.title}</h2>
            <div className="flex gap-4">
              <div className="flex-grow" style={{ flexBasis: '69%' }}>
                {renderChart()}
              </div>
              <div className="overflow-auto h-[42vh] min-w-[300px]" style={{ flexBasis: '31%', maxHeight: '69vh' }}>
                <p>{obj.summary}</p>
              </div>
            </div>
            {/* Micro container for the icon */}
            <div className="absolute bottom-[-40px] left-1/2 -translate-y-1/2 translate-x-1/2 w-28 h-11 bg-white rounded-full shadow-lg flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <button onClick={() => deleteBlock(obj.block_id)} title="Remove" className="flex items-center justify-center">
                <TbRowRemove className="text-2xl text-gray-800" />
              </button>
            </div>
          </div>
        );
      })}
      
      {/* Placeholder for the chat container */}
      <div className='bg-red w-100 col-span-1'>
      </div>
    </div>
  );
};

  // Renders the title or an input field based on editing mode
  const renderTitle = () => {
    if (isEditing) {
      return (
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          onBlur={toggleEdit}
          autoFocus
          className="bg-gray-100 p-1"
        />
      );
    }

    return <h1 onClick={toggleEdit} className="cursor-pointer">{title}</h1>;
  };
  
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const updateTitle = async (newTitle) => {
    await fetch(`${BASE_URL}/notebook-title`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        { 
          userId: username.multiFactor.user.uid,
          notebookId: notebookId, 
          title: newTitle,
        }),
    });
  };

  const toggleEdit = () => {
    analytics.logEvent('edit_notebook_title', { page: 'notebook' });
    if (isEditing) {
      updateTitle(title);
    }
    setIsEditing(!isEditing);
  };

  return (
    <AppLayout 
      title={renderTitle()}
      onAddButtonClick={() => { analytics.logEvent('add_block', { page: 'notebook' }); setShowAddContainer(!showAddContainer); setShowQueryContainer(false); }}
      onQueryButtonClick={() => { analytics.logEvent('edit_block', { page: 'notebook' }); setShowQueryContainer(!showQueryContainer); setShowAddContainer(false); }}
      addBtnActive={showAddContainer}
      queryBtnActive={showQueryContainer}
      username={username}
    >
      <div className="flex flex-grow relative">

        {showAddContainer && (
          <div className="fixed bg-white border rounded-3xl m-20 p-4 flex-none z-10 shadow-2xl" style={{ width: '369px', left: 0, top: 0 }}>
            <div className="flex justify-between items-center pb-2 mb-4">
              <h2 className="text-sm font-light text-[#333333]">Add Insights Block</h2>
              <button onClick={() => setShowAddContainer(false)} className="text-gray-300 hover:text-gray-700">
                <FaTimes size={16}/>
              </button>
            </div>
            <div className="grid gap-4 text-md text-[#333333]">
              
              <div onClick={() => handleSelection('Forecast')}><h3 className="font-bold pb-3">Forecast</h3>
                <ul>
                  <li className="flex items-center mb-2 p-3 cursor-pointer hover:bg-gray-100 rounded-xl"><FaChartLine className="mr-2 text-purple-500"/>Time Series </li>
                </ul>
              </div>          
              
              <div onClick={() => handleSelection('Anomaly')}><h3 className="font-bold pb-3">Detect</h3>
                <ul>
                  <li className="flex items-center mb-2 p-3 cursor-pointer hover:bg-gray-100 rounded-xl"><FaCodeBranch className="mr-2 text-purple-500"/>Anomalies</li>
                </ul>
              </div>

              <div onClick={() => handleSelection('Correlation')}><h3 className="font-bold pb-3">Visualize</h3>
                <ul>
                  <li className="flex items-center mb-2 p-3 cursor-pointer hover:bg-gray-100 rounded-xl"><TbChartBubbleFilled className="mr-2 text-purple-500"/>Correlations</li>
                </ul>
              </div>

            </div>
          </div>
        )}

        {showQueryContainer && (
          <div className="fixed bg-white border rounded-3xl m-20 p-4 flex-none z-10 shadow-2xl h-[89vh]" style={{ width: '369px', left: 0, top: 0 }}>
            <div className="flex justify-between items-center pb-2 mb-4">
              <h2 className="text-sm font-light w-[69%]">Edit Scenario</h2>
              <button onClick={() => setShowQueryContainer(false)} className="text-gray-300 hover:text-gray-700">
                <FaTimes size={16}/>
              </button>
            </div>
            <div>
              <div className="mb-4 p-2 rounded-xl shadow-sm w-full flex items-center justify-between">
                <span>{queryType}</span>
              </div>
              <select
                className="mb-4 p-2 border border-gray-300 rounded-xl shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 w-full"
                value={selectedFile}
                onChange={handleFileSelection}
              >
                {Array.isArray(meta.data) ? meta.data.map((fileName, index) => (
                  <option key={index} value={fileName}>{fileName}</option>
                )) : <option value={meta.data}>{meta.data}</option>}
              </select>

              <span>Choose Features</span>
                <div className="flex flex-wrap gap-2 mt-4 mb-9 overflow-auto" style={{ height: '18vh' }}>
                  {features.map((column, index) => (
                    <button
                      key={index}
                      className={`py-1 px-4 rounded-full text-black border border-blue-500 ${activeFeature.includes(column) ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-500 hover:text-white'}`}
                      onClick={() => handleColumnClick(column)}
                      style={{ height: '40px', minWidth: '100px' }} 
                    >
                      {column}
                    </button>
                  ))}
                </div>

              <span>Add Scenario</span>
              <textarea
                className="p-2 mt-3 border border-gray-300 rounded-xl w-full shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-none"
                value={queryText}
                onChange={(e) => setQueryText(e.target.value)}
                placeholder="Enter query"
                style={{height: '15vh'}}
              ></textarea>

              <button 
                  onClick={handleSubmit}
                  className="mt-4 p-2 text-white text-lg rounded-xl shadow transition duration-150 ease-in-out w-full bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-700 hover:to-purple-800"
                >
                  Generate Insights
              </button>

            </div>
          </div>
        )}

        <div className="w-full m-7">
          {renderObjects()}
        </div>
      </div>
    </AppLayout>
  );
};

export default Notebook;