import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';

import { read as XLSXRead, utils as XLSXUtils } from 'xlsx';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const Upload = ({ isVisible, onClose, onUploadStatusChange, username }) => {
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);

    const onDragEnter = () => wrapperRef.current.classList.add('border-blue-500');
    const onDragLeave = () => wrapperRef.current.classList.remove('border-blue-500');
    const onDrop = () => wrapperRef.current.classList.remove('border-blue-500');

    const onFileDrop = (e) => {
        e.preventDefault();
        const newFiles = e.dataTransfer ? e.dataTransfer.files : e.target.files;
        const updatedList = [...fileList, ...Array.from(newFiles)];
        setFileList(updatedList);
        // If you have an onFileChange prop
        // props.onFileChange(updatedList);
    }

    const fileRemove = (file) => {
        const updatedList = fileList.filter(f => f !== file);
        setFileList(updatedList);
        // If you have an onFileChange prop
        // props.onFileChange(updatedList);
    }

    const readFileAsArrayBuffer = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => resolve(event.target.result);
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };
    
    const handleUpload = async () => {
        let uploadError = false;
    
        for (const file of fileList) {
            const fileExt = file.name.split('.').pop().toLowerCase();
    
            if (fileExt === 'xlsx' || fileExt === 'xls' || fileExt === 'csv') {
                try {
                    const data = await readFileAsArrayBuffer(file);
                    const workbook = XLSXRead(data, { type: 'array' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const jsonData = XLSXUtils.sheet_to_json(worksheet);
    
                    // Use FormData to construct the request body
                    const formData = new FormData();
                    formData.append('file', file); // Append the original file
                    formData.append('data', JSON.stringify(jsonData)); // Append the file data
                    formData.append('userId', username.multiFactor.user.uid); // Append the userId
    
                    // Send data to server
                    const response = await fetch(`${BASE_URL}/upload`, {
                        method: 'POST',
                        body: formData // Send the formData
                    });
    
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
    
                    const serverResponse = await response.json();
                    console.log('Server response:', serverResponse);
                } catch (err) {
                    console.error('Error processing file:', file.name, err);
                    uploadError = true;
                }
            }
            // ... handle other file types
        }
        if (onUploadStatusChange) {
            if (uploadError) {
                onUploadStatusChange('Error uploading one or more files.');
            } else {
                onUploadStatusChange('All files uploaded successfully.');
            }
        }
    
        onClose();
    };
    

    if (!isVisible) return null;

    return (
        <div className="flex justify-center items-center w-full h-full bg-gray-100 bg-opacity-50 fixed top-0 left-0 transition-all duration-500 ease-in-out">
            <div className="bg-white p-6 rounded-lg shadow-lg relative transform translate-y-0 opacity-100">
                <button 
                    className="absolute top-3 lef-3 text-gray-500 hover:text-gray-700" 
                    onClick={onClose}
                >
                    X
                </button>
                <div
                    ref={wrapperRef}
                    className="border-2 border-dashed border-gray-300 p-4 mt-6 rounded-lg cursor-pointer hover:border-blue-500"
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onFileDrop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <div className="flex flex-col items-center justify-center">
                        <p>Drag & Drop your files here</p>
                        <input type="file" className="hidden" onChange={onFileDrop} multiple />
                    </div>
                </div>

                {
                    fileList.length > 0 && (
                        <div className="mt-4">
                            <p className="text-lg font-semibold">Ready to upload</p>
                            {
                                fileList.map((file, index) => (
                                    <div key={index} className="flex items-center justify-between mt-2 p-2 border-b">
                                        <div>
                                            <p className="text-sm">{file.name}</p>
                                            <p className="text-xs text-gray-500">{file.size} bytes</p>
                                        </div>
                                        <button onClick={() => fileRemove(file)} className="text-red-500 hover:text-red-700">Remove</button>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }

                <button 
                    className="mt-4 bg-blue-500 text-white rounded-full py-2 px-4 hover:bg-blue-700"
                    onClick={handleUpload}
                >
                    Upload
                </button>
            </div>
        </div>
    );
}

Upload.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
}

export default Upload;
