import React from 'react';
import { useNavigate } from 'react-router-dom';

const CancelPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center justify-center min-h-screen bg-red-50">
      <div className="text-center">
        <svg className="w-16 h-16 mx-auto text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg>
        <h1 className="mt-4 text-3xl font-semibold text-gray-800">Payment Cancelled</h1>
        <p className="mt-2 text-gray-600">Your payment was not completed. If you have any questions, please contact our support team.</p>
        <button 
          onClick={() => navigate('/n')} // Navigate to the home page (adjust the route as needed)
          className="px-4 py-2 mt-4 text-sm font-semibold text-red-600 bg-red-100 rounded hover:bg-red-200">
          Back to Platform
        </button>
      </div>
    </div>
  );
};

export default CancelPage;
