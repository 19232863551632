import React from 'react';
import { Link } from 'react-router-dom';
import logo from './trianglai-icon.svg';
import { PiSparkle, PiPlusBold } from "react-icons/pi";
import { PiMagicWandFill } from "react-icons/pi";
import Pricing from './Pricing';

const AppLayout = ({ title, onAddButtonClick, onQueryButtonClick, children, addBtnActive, queryBtnActive, username }) => {
  const [showPricing, setShowPricing] = React.useState(false);

  const navigateToPricing = () => {
    setShowPricing(true); 
  };

  const closePricingAndGoBack = () => {
    setShowPricing(false);
  };

  return (
    <>
    <div className="flex h-screen overflow-hidden">
      <div className="bg-white border-r border-gray w-16 flex flex-col fixed h-full z-30">
        <div className="flex justify-center items-center border-b border-gray h-16">
          <Link to={`/n/`}><img src={logo} alt="Logo" className="w-10 h-10"/></Link>
        </div>
        <div className="flex-grow mt-3 mx-auto flex flex-col items-center ">
          <button 
              onClick={onAddButtonClick} 
              className={`p-2 w-11 h-11 flex justify-center items-center text-lg rounded-xl ${addBtnActive ? 'bg-gray-900 text-white' : 'hover:bg-gray-100'}`}
          >
              <PiPlusBold className='h-7 w-7s'/>
          </button>
          <span className='text-center mt-1 mb-2'>Add</span>
          <button 
              onClick={onQueryButtonClick} 
              className={`p-2 w-11 h-11 flex justify-center items-center text-lg rounded-xl ${queryBtnActive ? 'bg-gray-900 text-white' : 'hover:bg-gray-100'}`}
          >
              <PiSparkle className='h-7 w-7'/>
          </button>
          <span className='text-center'>Edit</span>

          <button 
            onClick={navigateToPricing}
            className="fixed p-3 rounded-xl bottom-9 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-blue-400 hover:to-purple-400 focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-800 shadow-lg transition-all duration-300 ease-in-out"
            style={{
              boxShadow: '0 6px 20px rgba(128, 90, 213, 0.5)', // Shadow with colors of status and wealth
            }}
          >
          <PiMagicWandFill className="h-6 w-6 text-white" />
          </button>
          {showPricing && (
            <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 100 }}>
              <Pricing onClose={closePricingAndGoBack} userId={username.multiFactor.user.uid}/>
            </div>
          )}

          <span className='fixed bottom-3'>
            More
          </span>
          
        </div>
      </div>
      <div className="ml-16 flex flex-col flex-grow">
        <div className="bg-white border-b border-gray h-16 flex items-center justify-between px-4 fixed top-0 right-0 left-16 z-10">
          <h1>{title}</h1>
        </div>
        <div className="pt-16 flex-grow overflow-auto">
          {children}
        </div>
      </div>
    </div>
    </>
  );
};

export default AppLayout;
