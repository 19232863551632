// src/firebase.js
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics'; // Ensure you're importing the compat version of analytics

const firebaseConfig = {
    apiKey: "AIzaSyDcsG5XxDoHdmmtWVtXCcIycYflloLwA_U",
    authDomain: "cuanto-set-waitlist.firebaseapp.com",
    projectId: "cuanto-set-waitlist",
    storageBucket: "cuanto-set-waitlist.appspot.com",
    messagingSenderId: "1060822109532",
    appId: "1:1060822109532:web:013f4c01cb66d151240599",
    measurementId: "G-61WCKXS8XE"
};

// Initialize Firebase only if it hasn't been initialized yet
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Initialize Firebase services
const auth = firebase.auth();
const analytics = firebase.analytics(); // Initialize analytics

// Export the Firebase services for use elsewhere in your application
export { auth, analytics };
