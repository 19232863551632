import React, { useState } from 'react';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useNavigate, useLocation } from 'react-router-dom';
import logo from './trianglai-icon.svg';
import './custom.css';
import { FcGoogle } from 'react-icons/fc';

const SignIn = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
      navigate(location.state?.from || '/n');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setEmail('');
      setPassword('');
      setError(null);
      navigate(location.state?.from || '/n');
    } catch (err) {
      setError(err.message);
    }
  };

  const handleSignUpClick = () => {
    navigate('/signup');
  };

  return (
    <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 mx-auto bg-white p-8 my-10 rounded-3xl shadow-lg">
      <div className="text-center mb-8">
        <img className="mx-auto h-16 w-auto" src={logo} alt="Company Logo" />
        <h2 className="mt-2 text-center text-2xl font-bold text-gray-900">Sign in to TriangleAI</h2>
      </div>
      
      <div id="firebaseui-auth-container"></div>
      <button 
        onClick={signInWithGoogle} 
        className="w-full bg-gray-100 text-[#3D4043] flex justify-center items-center text-xl py-3 rounded-full mt-4 mb-4"
      >
        <FcGoogle className="mr-2" />
        Sign in with Google
      </button>
      
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300"></div>
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="px-2 bg-white text-gray-500">or</span>
        </div>
      </div>

      <form onSubmit={handleSignIn} className="mt-4 space-y-6">
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm -space-y-px">
          <div>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-2 mb-4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mt-2 mb-4 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        {error && <p className="text-red-500">{error}</p>}
        <div>
          <button
            type="submit"
            className="w-full group relative flex justify-center py-3 px-4 border border-transparent text-xl font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Sign In
          </button>
        </div>
        <div className="text-center">
          <button
            onClick={handleSignUpClick}
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignIn;