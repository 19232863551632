import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip'; // Import d3-tip
import './Tips.css';

const MultiLineChart = ({ data }) => {
  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = wrapperRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      });
    });
    resizeObserver.observe(observeTarget);
    return () => resizeObserver.unobserve(observeTarget);
  }, [wrapperRef]);

  useEffect(() => {
    if (!data || data.length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear SVG content for fresh redraw

    const margin = { top: 20, right: 0, bottom: 30, left: 120 },
          width = dimensions.width - margin.left - margin.right,
          height = dimensions.height - margin.top - margin.bottom;

    svg.attr('width', dimensions.width)
       .attr('height', dimensions.height);

    const xScale = d3.scaleTime()
                     .domain(d3.extent(data, d => new Date(d.Date)))
                     .range([0, width]);

    const yScale = d3.scaleLinear()
    .domain([
        d3.min(data, d => Math.min(d.forecast_lower, d.actual !== 999999 ? d.actual : d.forecast)),
        d3.max(data, d => Math.max(d.forecast_upper, d.actual !== 999999 ? d.actual : d.forecast))
    ])
    .range([height, 0])
    .nice();

    const chartArea = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
      
    const lineGenerator = d3.line()
      .x(d => xScale(new Date(d.Date)))
      .y(d => yScale(d.value))
      .curve(d3.curveBasis);

    // Forecast Band
    const areaGenerator = d3.area()
      .x(d => xScale(new Date(d.Date)))
      .y0(d => yScale(d.forecast_lower))
      .y1(d => yScale(d.forecast_upper))
      .curve(d3.curveBasis);

    chartArea.append('path')
        .datum(data)
        .attr('fill', 'lightblue')
        .attr('d', areaGenerator);

    const xAxis = d3.axisBottom(xScale)
    .ticks(5) // Reduce the number of ticks for simplicity
    .tickSize(-height) // Extend ticks across the chart as light gridlines
    .tickPadding(10); // Add some padding for readability

    const yAxis = d3.axisLeft(yScale)
    .ticks(5) // Similarly, reduce the number of ticks on the y-axis
    .tickSize(-width) // Extend ticks across the chart as light gridlines
    .tickPadding(10); // Padding for readability

    chartArea.append('g')
    .attr('class', 'x axis')
    .attr('transform', `translate(0, ${height})`)
    .call(xAxis)
    .selectAll('line') // Target the lines specifically for styling
    .attr('stroke', '#ddd') // Light color for gridlines for subtlety
    .attr('stroke-dasharray', '2,2'); // Dashed effect for the gridlines

    chartArea.append('g')
    .attr('class', 'y axis')
    .call(yAxis)
    .selectAll('line')
    .attr('stroke', '#ddd') // Consistent with x-axis gridlines
    .attr('stroke-dasharray', '2,2');

    // Redefining the class styling for the axis path (the domain line) to be less pronounced
    d3.selectAll('.axis path')
    .attr('stroke', '#fff') // Softer color for the domain line
    .attr('stroke-width', '1');

    const tip = d3tip()
    .attr('class', 'd3-tip')
    .html((event, d) => {
      // HTML structure for line representations in the tooltip
      const lineStyle = (color, dashed = false) => `<div style="width: 15px; height: 2px; background-color: ${color}; ${dashed ? 'border-top: 2px dashed ' + color : ''}; margin-right: 5px; display: inline-block;"></div>`;

      let tooltipContent = `<div style="display: flex; align-items: center;">${lineStyle('#C0C0C0') /* lower and upper line */}<span>Lower & Upper: ${d.forecast_lower} - ${d.forecast_upper}</span></div>`;

      if (d.actual !== 999999) {
        tooltipContent += `<div style="display: flex; align-items: center;">${lineStyle('#003366') /* actual line */}<span>Actual: ${d.actual}</span></div>`;
      }

      tooltipContent += `<div style="display: flex; align-items: center;">${lineStyle('#007FFF', true) /* forecast line */}<span>Forecast: ${d.forecast}</span></div>`;

      return tooltipContent;
    });
    svg.call(tip);
    
    const guideline = chartArea.append('line')
                               .attr('stroke', 'grey')
                               .attr('y1', 0)
                               .attr('y2', height)
                               .style('opacity', 0);

    // Shaded Area for Forecast Bounds
    chartArea.append('path')
    .datum(data)
    .attr('class', 'forecast-bounds')
    .attr('fill', '#DAE8FC')
    .attr('opacity', 0.3)
    .attr('d', areaGenerator);

    // Adjust the Forecast Line for clarity in the context of the shaded bounds
    chartArea.select('path[d][stroke="#C0C0C0"]')
    .attr('stroke-dasharray', '5,5') // Make the forecast line dashed for better distinction

    // Actual Line
    chartArea.append('path')
        .datum(data.filter(d => d.actual !== 999999).map(d => ({ Date: d.Date, value: d.actual })))
        .attr('fill', 'none')
        .attr('stroke', '#003366')
        .attr('stroke-width', 3)
        .attr('d', lineGenerator);

    // Forecast Line
    chartArea.append('path')
        .datum(data.map(d => ({ Date: d.Date, value: d.forecast })))
        .attr('fill', 'none')
        .attr('stroke', '#007FFF')
        .attr('stroke-dasharray', '5,5')
        .attr('stroke-width', 3)
        .attr('d', lineGenerator);

    // Event listeners remain the same for interactivity
    chartArea.append('rect')
        .attr('width', width)
        .attr('height', height)
        .style('fill', 'none')
        .style('pointer-events', 'all')
        .on('mousemove', function(event) {
          const [xPos] = d3.pointer(event);
          const xDate = xScale.invert(xPos);
          const bisectDate = d3.bisector(d => new Date(d.Date)).left;
          const idx = bisectDate(data, xDate, 1);
          const selectedData = data[idx - 1];

          tip.show(event, selectedData, this);
          guideline.attr('x1', xPos).attr('x2', xPos).style('opacity', 1);
        })
        .on('mouseout', () => {
          tip.hide();
          guideline.style('opacity', 0);
        });
        }, [data, dimensions]);
        
        return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
        <svg ref={svgRef}></svg>
        </div>
        );
        };
        
        export default MultiLineChart;