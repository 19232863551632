import React, { useState } from 'react';
import logo from './trianglai-icon.svg';
import { loadStripe } from '@stripe/stripe-js';
import { PiXThin } from "react-icons/pi";

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const Plan = ({ name, price, features, billingCycle, userId, isHighlighted = false }) => {
  const stripePromise = loadStripe('pk_live_51NTUfbBhBxXSh10siumXGM3BF1RfElLiHpn0vI9aN6jPrNCXACsMDoMDOmNEqEOhPyiGGghgXogOZbTxidSg3d1600tuq06JL4');

  const initiateStripeCheckout = async (planName, billingCycle, userId) => {
  // Assuming stripePromise is already defined outside of this snippet correctly
  const stripe = await stripePromise;
  const response = await fetch(`${BASE_URL}/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ planName, billingCycle, userId }),
  });

  if (response.ok) {
    const session = await response.json();
    if (session.session_url) { // Ensure your backend is adjusted to return `session_url`
      // Redirect the user to the Stripe Checkout page
      window.location.href = session.session_url;
    } else {
      // Handle the case where `session_url` is not provided
      console.error("Session URL not provided.");
    }
  } else {
    console.error("Could not connect to the backend server. Please try again.");
  }
};
  
  const formatPrice = (price, cycle) => {
      if (cycle === 'annual') {
        return (
          <div className="grid grid-cols-2 items-center gap-2">
            <span className="text-5xl font-bold col-span-1 text-blue-500">${price}</span>
            <div className="flex flex-col col-span-1">
              <span className="text-sm text-gray-500 text-left">per month</span>
              <span className="text-sm text-gray-500 text-left">billed yearly</span>
            </div>
          </div>
        );
      } else {
        return (
          <div className="grid grid-cols-2 items-center gap-2">
            <span className="text-5xl font-bold col-span-1 text-blue-500">${price}</span>
            <div className="flex flex-col col-span-1">
              <span className="text-sm text-gray-500 text-left">per month</span>
              <span className="text-sm text-gray-500 text-left">billed yearly</span>
            </div>
          </div>
        );
      }
    };
      

    const planContainerClass = `bg-white p-11 rounded-xl shadow-lg flex flex-col text-left border-2 ${isHighlighted ? 'border-blue-500 scale-105' : 'border-transparent'} transform transition-all duration-300 ease-in-out mx-auto`;

    const buttonClass = `mt-3 text-lg font-semibold py-3 px-16 rounded-xl shadow-md transition-colors duration-150 ${price[billingCycle] !== 'Custom' ? 'bg-blue-600 hover:bg-blue-700 text-white' : 'bg-gray-800 hover:bg-gray-900 text-white'}`;

    const handleButtonClick = () => {
    if (price[billingCycle] === 'Custom') {
        // Redirect to "this.com" for Enterprise plans
        window.location.href = "https://calendly.com/treebel/custom-ai-for-enterprise";
    } else {
        // For other plans, integrate with Stripe
        initiateStripeCheckout(name, billingCycle, userId); // This function should be defined elsewhere
      }
    };

    return (
      <div className={planContainerClass}>
          <h2 className="text-xl md:text-2xl font-semibold mb-3">{name}</h2>
          <p className="text-4xl font-bold mb-6">
              {price[billingCycle] !== 'Custom' ? formatPrice(price[billingCycle], billingCycle) : <span className="text-5xl font-bold text-blue-500">Customize</span>}
          </p>
          <ul className="self-stretch mb-5">
              {features.map((feature, index) => (
                  <li key={index} className="mb-2 text-sm md:text-base">{feature}</li>
              ))}
          </ul>
          <button className={buttonClass} onClick={handleButtonClick}>
              {price[billingCycle] !== 'Custom' ? 'Buy Now' : 'Contact Sales'}
          </button>
      </div>
      );
  };

const Pricing = ({ onClose, userId }) => {
  const [billingCycle, setBillingCycle] = useState('annual');  

  const plans = [
    {
      name: 'Premium',
      price: { monthly: 215, annual: 58 },
      features: [
        'Unlimited Heatmap Analysis',
        'Unlimited AI Forecast Analysis',
        'Unlimited AI Anomaly Analysis',
        <br></br>,      
        '-',
        '-',
        '-',
        <br></br>,      
        'Includes all updates'
      ],
      highlighted: true,
    },
    {
      name: 'Enterprise',
      price: { monthly: 'Custom', annual: 'Custom' },
      features: [
        'Unlimited Heatmap Analysis',
        'Unlimited AI Forecast Analysis',
        'Unlimited AI Anomaly Analysis',
        <br></br>,      
        '+ Real-Time Data Sync',        
        '+ Custom AI Reporting',
        '+ Enterprise AI Chat',
        <br></br>,      
        'Includes all updates'
      ],
    },
  ];

  return (
    <div className="fixed inset-0 bg-gray-100 p-8 overflow-auto z-99">
        <div className="flex justify-between items-center mb-3">
            <img src={logo} alt="Logo" className="w-12 h-12"/>
            <h1 className="text-3xl md:text-4xl font-bold">Pricing Plans</h1>
            <button onClick={onClose} className="text-3xl p-1 rounded-full hover:bg-gray-200"><PiXThin/></button>
        </div>
        
        <div className="flex justify-center items-center gap-4 mb-3">
            <div className='bg-black p-1 rounded-full flex justify-center items-center gap-4 mb-8'>
            <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-6 py-3 text-sm md:text-base font-medium transition-colors duration-150 ${billingCycle === 'monthly' ? 'bg-blue-600 text-white rounded-full' : 'text-white hover:bg-blue-600 hover:text-white rounded-full'}`}
            >
                Monthly
            </button>
            <button
                onClick={() => setBillingCycle('annual')}
                className={`px-6 py-3 text-sm md:text-base font-medium transition-colors duration-150 ${billingCycle === 'annual' ? 'bg-green-500 text-white rounded-full' : 'text-white hover:bg-green-500 hover:text-white rounded-full'}`}
            >
                Yearly
                {billingCycle === 'annual' && <span className="text-md ml-2 py-3 px-3 bg-green-200 rounded-full text-green-500">73% off</span>}
            </button>
            </div>
        </div>

        <div className="flex justify-center">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
            {plans.map((plan) => (
              <Plan 
                key={plan.name}
                name={plan.name} 
                price={plan.price} 
                features={plan.features}
                billingCycle={billingCycle}
                userId={userId}
                isHighlighted={plan.highlighted}
              />
            ))}
          </div>
        </div>
        
    </div>
  );
};

export default Pricing;
