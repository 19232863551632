import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { analytics } from '../firebase';

import './styles.css';
import hero from './hero.svg';
import tsm from './tsm2.png';
import logo from './trianglai-logo.svg';
import img1 from './img1.svg';
import img2 from './img2.svg';
import img3 from './img3.svg';
import img4 from './img4.svg';
import img5 from './img5.svg';

const LandingPage = () => {
    useEffect(() => {
      analytics.logEvent('landing_page_view', { page: 'd0' });
    }, []);

    const trackClick = (label) => {
      analytics.logEvent('landing_page_click', { button: label });
    };
    
    return (
    <div className="mx-auto text-black relative min-h-screen">
          {/* SEO: Title and Meta Description for better search visibility */}
          <Helmet>
              <title>Maximize ROI | TriangleAI</title>
              <meta name="description" content="Use AI to turn data points into insights with TriangleAI. Business intelligence forecasting. Data-driven decision-making with Generative AI." />
          </Helmet>

          <div className="relative z-20 py-3">
              {/* Transparent Container with Rounded Corners and Shadow for Hover Effect */}
              <div className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-3xl shadow-xl p-3 mx-auto container max-w-6xl">
                  <header className="text-center relative">
                      <nav className="flex justify-between items-center">
                          {/* SEO: Use meaningful alt text for the logo to improve accessibility and provide context to search engines */}
                          <img src={logo} alt="TriangleAI Logo" className="h-14 mr-2"/>
                          <div className="flex justify-end items-center gap-4">
                              <div className="text-lg">
                                  <Link to='/signin' className="px-2 py-2 hover:bg-yellow-300 hover:text-black rounded-full transition ease-in-out duration-200">
                                      Log in
                                  </Link>
                              </div>
                              <div className="h-6 w-px bg-black"></div>
                              <a href="https://calendly.com/treebel/custom-ai-for-enterprise" target="_blank" rel="noopener noreferrer">
                                  {/* CTA: Include a clear call to action with a verb such as "Book" and make sure the button is descriptive for both users and search engines */}
                                  <button 
                                  onClick={() => trackClick('header_CTA_clicked')}
                                  className="text-black bg-yellow-400 hover:bg-yellow-500 transition ease-in-out duration-200 text-lg font-bold px-6 py-2 rounded-full border border-black">
                                      Book Demo
                                  </button>
                              </a>
                          </div>
                      </nav>
                  </header>
              </div>
          </div>      

      <div className="mt-16 text-center relative z-10">
        <h1 className="text-6xl font-bold mb-3">Maximize ROI with a click</h1>
          <p className="text-3xl mb-6">Just click Generate with AI and viola!<br></br> Accurate forecast in seconds.</p>
          <div className="flex justify-center space-x-4"> {/* Container for buttons */}
            <Link to="/n">
              <button 
              onClick={() => trackClick('hero_CTA_clicked')}
              className="text-black bg-yellow-400 hover:bg-yellow-500 transition ease-in-out duration-200 text-lg font-bold px-6 py-2 rounded-full border border-black">
                  Try it Free (no cc required)
              </button>
            </Link>
            {/* <a href="https://calendly.com/treebel/custom-ai-for-enterprise" target="_blank" rel="noopener noreferrer">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-full transition duration-200 ease-in-out shadow-xl hover:scale-95">Schedule Demo</button>
            </a> */}
          </div>
          <div className="mt-3 w-[57%] mx-auto">
            <img src={hero} alt="Business intelligence forecasting - Data-driven decision-making with Generative AI for Startups and SMBs." className="mx-auto" style={{ maxWidth: '100%', height: 'auto' }} />
          </div>
        </div>
  
      <div className="features-section mx-auto mt-28">
        {/* Feature 1 */}
        <div className="feature w-3/4 mx-auto" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="feature-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '136px', height: '40px', backgroundColor: 'transparent', border: '2px solid #000', borderRadius: '20px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>Data to Insights</div>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold' }}>AI forecasts & More</h1>
            <h2 className='text-3xl text-black'>Get the most out of your data with a click. 
            <br></br>Just upload to automatically turn your data points into insights with AI.</h2>
          </div>
        </div>
        <img src={img1} alt="AI forecasts & More - Get the most out of your data with a click. - Just upload to automatically turn your data points into insights with AI." className="rounded-lg mx-auto" style={{ width: '73%', height: 'auto', maxWidth: '100%' }} />

      </div>

      <div className="features-section mx-auto mt-28">
        {/* Feature 2 */}
        <div className="feature w-3/4 mx-auto" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="feature-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '136px', height: '40px', backgroundColor: 'transparent', border: '2px solid #000', borderRadius: '20px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>Step 1</div>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold' }}>Create a Journal </h1>
            <h2 className='text-3xl text-black'>Create ML powered insight journals in seconds. 
            <br></br>Click create, upload your sales, customer and ERP data to create instant insights with generative summaries.</h2>
          </div>
        </div>
        <img src={img2} alt="Create a Journal - Create ML powered insight journals in seconds. - Click create, upload your sales, customer and ERP data to create instant insights with generative summaries." className="rounded-lg mx-auto" style={{ width: '73%', height: 'auto', maxWidth: '100%' }} />

      </div>

      <div className="features-section mx-auto mt-28">
        {/* Feature 3 */}
        <div className="feature w-3/4 mx-auto" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="feature-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '136px', height: '40px', backgroundColor: 'transparent', border: '2px solid #000', borderRadius: '20px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>Step 2</div>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold' }}>Click Add</h1>
            <h2 className='text-3xl text-black'>Select Time Series Forecast</h2>
          </div>
        </div>
        <img src={img3} alt="Click Add - Select Time Series Forecast" className="rounded-lg mx-auto" style={{ width: '73%', height: 'auto', maxWidth: '100%' }} />

      </div>

      <div className="features-section mx-auto mt-28">
        {/* Feature 4 */}
        <div className="feature w-3/4 mx-auto" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="feature-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '136px', height: '40px', backgroundColor: 'transparent', border: '2px solid #000', borderRadius: '20px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>Step 3</div>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold' }}>Generate Insights</h1>
            <h2 className='text-3xl text-black'>Choose your features, add a scenario for your forecast, then click generate insights.</h2>
          </div>
        </div>
        <img src={img4} alt="Generate Insights - Choose your features, add a scenario for your forecast, then click generate insights." className="rounded-lg mx-auto" style={{ width: '73%', height: 'auto', maxWidth: '100%' }} />

      </div>

      <div className="features-section mx-auto mt-28">
        {/* Feature 5 */}
        <div className="feature w-3/4 mx-auto" style={{ display: 'flex', alignItems: 'flex-start' }}>
          <div className="feature-content" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '20px' }}>
            <div style={{ width: '169px', height: '40px', backgroundColor: 'transparent', border: '2px solid #000', borderRadius: '20px', color: '#000', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>Generative Insights</div>
            <h1 style={{ fontSize: '4em', fontWeight: 'bold' }}>Analyze and Measure</h1>
            <h2 className='text-3xl text-black'>Your advanced analysis is ready for review with a GPT summary, ML powered forecast with model metrics and more.</h2>
          </div>
        </div>
        <img src={img5} alt="Analyze and Measure - Your advanced analysis is ready for review with a GPT summary, ML powered forecast with model metrics and more" className="rounded-lg mx-auto" style={{ width: '73%', height: 'auto', maxWidth: '100%' }} />

      </div>

      {/* Call to Action Section */}
      <section className="cta text-center py-16 mt-16">
        <h2 className="text-3xl font-bold mb-4">Yes, it's that easy.</h2>
        <p className="text-xl mb-8">Maximize your data ROI starting today</p>
        <Link to="/n">
          <button className="bg-white text-black border border-yellow-300 font-bold py-4 px-8 rounded-full transition duration-200 ease-in-out shadow-xl hover:scale-95">
            Get insights now
          </button>
        </Link>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-16 text-black mt-28">
        <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold text-center mb-12">Our Visionary Clients</h2>
            
            <div className="rounded-lg shadow-2xl overflow-hidden" style={{ boxShadow: '0 6px 28px 0 rgba(255, 255, 255, 0.5), 0 10px 15px 0 rgba(255, 255, 255, 0.15)' }}>
                <div className="px-6 md:px-8">
                    <div className="md:flex md:items-end"> {/* Changed md:items-center to md:items-end to align items to the bottom */}
                        <div className="md:shrink-0">
                            <img src={tsm} alt="tsm" className="h-48 w-48 rounded-full mx-auto md:mx-0 md:rounded-none md:h-auto md:w-full"/>
                        </div>
                        <div className="mt-6 py-6 md:mt-0 md:ml-6 text-center md:text-left">
                          <h3 className="text-2xl font-bold mb-3">A Message from Our Founder</h3>
                          <p className="text-gray-300 text-lg">From the desk of Treebel,</p>                 
                          <h1>Prepare for every Scenario.</h1>
                          <br></br>
                          <h2>Imagine you're having an interesting conversation at an event.</h2>
                          <h2>You get an idea and wonder 'Can we do that? How would this impact us?'</h2>
                          <br></br>
                          <h2>What if you could see the exact impact of that scenario on your business?</h2>
                          <h2>Yould could run an AI scenario analysis on your investments, revenue, operations and more. You could perpare for any scenario.</h2>
                          <br></br>
                          <h2>That's what TriangleAI is. I'm a seasoned financial data scientist and I specilize in AI, Forecasting and Modeling Software Tools.</h2>
                          <br></br>
                          
                          <h2>Here are my 3 takeaways from a decade in finance, technology and AI:</h2>
                          <li>Proactively track trends, patterns and outlooks.</li>
                          <li>Finance will be completely redefined and reimagined.</li>
                          <li>The AI era started, everything will change at hyper speed.</li>

                          <br></br>
                          <h2>Our visionary clients share this purpose, this mission to proactively perpare for every scenario our organization's encounter. We do that by challenging the status quo.
                            If you want more control for your growth, cashflow and ROI then join the visionaries.</h2>
                          <br></br>
                          <h2>Say no to less, demand the best service, achieve the most desired scenario with AI.</h2>

                          <p className="font-bold mt-4">To our shared horizon,</p>
                          <p className="font-bold">Treebel</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta text-center py-16">
        <h2 className="text-3xl font-bold mb-4">Ready to Redefine Finance?</h2>
        <p className="text-xl mb-8">Join the forefront of financial innovation with TriangleAI. Embrace the AI revolution today.</p>
        <Link to="/n">
          <button className="bg-white text-black font-bold py-4 px-8 rounded-full transition duration-200 ease-in-out shadow-xl hover:scale-95">
            Get Started Today
          </button>
        </Link>
      </section>
  
      {/* Footer */}
      <footer className="footer py-8">
        <div className="container mx-auto px-4 text-center">
          <div className="logo font-bold text-2xl mb-4">© {new Date().getFullYear()} TriangleAI.</div>
          <span>All rights reserved.</span>
          {/* Footer content updated as per user requirement */}
        </div>
      </footer>
  
    </div>
  );
  };
  export default LandingPage;
  