import React, { useEffect, useState } from 'react';

const words = ["NetSuite", "Salesforce", "HubSpot", "Zendesk", "Tableau"];

const HeroSection = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 3000); // Change word every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="flex items-center justify-center h-screen bg-gray-900 text-white">
      <div className="text-center p-6">
        <h1 className="text-6xl font-bold mb-4">
          Chat with <span className="text-blue-500">{words[currentWordIndex]}</span>
        </h1>
        <p className="text-xl mt-2">Centralize your entire CRM, ERP, BI and more.</p>
        <div className="mt-8">
          <a href="/signup" className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
            Get Started
          </a>
        </div>
        <div className="hidden">
          {words.map((word, index) => (
            <span key={index}>The {word} App </span>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
