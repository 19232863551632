import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './trianglai-icon.svg'; 
import { FaPlus, FaSpinner } from 'react-icons/fa';
import { PiMagicWandFill } from "react-icons/pi";
import Pricing from './Pricing';

// GA
import { analytics, auth } from '../firebase';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const ProjectsPage = ({ title = 'Insight Journal', username, isPremiumUser }) => {
  const [showPricing, setShowPricing] = React.useState(false);

  const navigateToPricing = () => {
    setShowPricing(true); // Assuming you want to show the Pricing component as an overlay
  };

  const closePricingAndGoBack = () => {
    setShowPricing(false);
  };

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [showAddData, setShowAddData] = useState(false);
  const [data, setData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [checkedFileNames, setCheckedFileNames] = useState([]);

  const [isUploading, setIsUploading] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const modalRef = useRef();
  const fileInputRef = useRef(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [projectId, setProjectId] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  // GA - Start // 
  useEffect(() => {
    analytics.logEvent('project_page', { page: 'insights hub' });
  }, []);
  // GA - END //

  const sortedProjects = projects.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

  // Function to handle row click, navigating to the notebook
  const handleRowClick = (projectId) => {
    navigate(`/n/${projectId}`);
  };

  const handleCheckboxChange = (fileName, isChecked) => {
    if (isChecked) {
      // Add the file name to the array if it's checked
      setCheckedFileNames(prev => [...prev, fileName]);
    } else {
      // Remove the file name from the array if it's unchecked
      setCheckedFileNames(prev => prev.filter(name => name !== fileName));
    }
  };
  
  const toggleUserDropdown = () => {
    setShowUserDropdown(!showUserDropdown);
  };

  const handleLogOut = () => {
    auth.signOut();
    navigate('/signin');
  };
  
  const handleCreateClick = async () => {
    try {
      const createResponse = await fetch(`${BASE_URL}/create-notebook`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: username.multiFactor.user.uid,
          files: checkedFileNames,
        }),
      });
  
      const projectData = await createResponse.json();
  
      if (createResponse.ok) {
        navigate(`/n/${projectData.id}`);

      } else {
        console.error("Failed to create project");
      }
    } catch (error) {
      console.error("Error creating project:", error);
    }
  };
  
  // Function to fetch data list from /my_data
  const fetchDataList = async () => {
    try {
      const response = await fetch(`${BASE_URL}/my_data?userId=${username.multiFactor.user.uid}&directory=notebooks/`);
      const fileNames = await response.json();
      if (response.ok) {
        setData(fileNames); // Directly use the returned array of filenames
      } else {
        console.error('Failed to fetch data list');
      }
    } catch (error) {
      console.error('Error fetching data list:', error);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      // Set files directly instead of their names
      setSelectedFiles(files);
      setIsUploading(true);
      setUploadMessage('File uploading...');
      uploadFiles(files); // Pass file objects for uploading
    }
  };
  
  const uploadFiles = async (files) => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));
    formData.append('userId', username.multiFactor.user.uid);
    formData.append('directory', 'notebooks/');

    setIsUploading(true); // Begin upload
    setUploadMessage(''); // Reset upload message

    try {
      const response = await fetch(`${BASE_URL}/upload_file`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Delay setting the upload message until after the fetch completes
        setUploadMessage('File upload complete: Successful');
        fetchDataList(); 
      } else {
        // Handle non-OK response
        setUploadMessage('File upload complete: Failed');
      }
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploadMessage('File upload complete: Failed');
    } finally {
      setIsUploading(false); // End upload
      setSelectedFiles([]); // Optionally clear selected files after upload
    }
  };

  // Function to fetch data when "Create a new Notebook" is clicked
  const handleFetchData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/my_data?userId=${username.multiFactor.user.uid}&directory=notebooks/`);
      const fetchedData = await response.json();
      setData(fetchedData || []); // Default to an empty array if no data

      setShowAddData(true); // Show modal to display data or upload option
    } catch (error) {
      console.error('Error fetching data:', error);
      setShowAddData(true); // Ensure modal is shown even if fetch fails
    }
  };
  
  const fetchProjects = async () => {
    try {
      const response = await fetch(`${BASE_URL}/my-notebooks?userId=${username.multiFactor.user.uid}`);
      const data = await response.json();
      setProjects(data.projects); // Assuming setProjects is your state setter for projects
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };
  
  useEffect(() => {
    fetchProjects(); // Initial fetch of projects
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAddData(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (uploadMessage) {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 1000); // Message will fade out after 3 seconds
    }
  }, [uploadMessage]);
  
  const deleteProject = async (projectId) => {
    try {
      const response = await fetch(`${BASE_URL}/notebook-delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          notebookId: projectId,
          userId: username.multiFactor.user.uid,
        }),
      });
  
      if (response.ok) {
        console.log('Delete Success:', await response.json());
        fetchProjects(); // Refresh projects list after successful deletion
      } else {
        console.error('Delete Failed:', response.status);
      }
    } catch (error) {
      console.error('Network Error:', error);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Fixed Sidebar (empty for ProjectsPage as per requirement) */}
      <div className="border-r border-gray w-16 flex flex-col fixed z-10">
        <div className="bg-white flex justify-center items-center border-b border-gray h-16 cursor-pointer">
            <Link to={`/n/`}><img src={logo} alt="Logo" className="w-10 h-10"/></Link>
        </div>
        
        <div className="flex-grow mt-3 mx-auto flex flex-col items-center ">
          <button 
            onClick={navigateToPricing}
            className="fixed p-3 rounded-xl bottom-9 bg-gradient-to-r from-purple-600 to-blue-600 hover:from-blue-400 hover:to-purple-400 focus:ring-4 focus:ring-yellow-300 dark:focus:ring-yellow-800 shadow-lg transition-all duration-300 ease-in-out"
            style={{
              boxShadow: '0 6px 20px rgba(128, 90, 213, 0.5)', // Shadow with colors of status and wealth
            }}
          >
            <PiMagicWandFill className="h-6 w-6 text-white" />
          </button>
            {showPricing && (
              <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center">
                <Pricing onClose={closePricingAndGoBack} userId={username.multiFactor.user.uid}/>
              </div>
            )}
            <span className='fixed bottom-3'>
            More
            </span>
          </div>
      </div>

      {/* Main content wrapper */}
        <div className="ml-16 flex flex-col flex-grow bg-white z-0">
          {/* Fixed Title Bar */}
          <div className="bg-white border-b border-gray h-16 flex items-center justify-between px-4 fixed top-0 right-0 left-16 z-1">
          <h1>{title}</h1>
          {/* User Initial and Toggle */}
          <div onClick={toggleUserDropdown} className="cursor-pointer bg-black rounded-lg w-8 h-8 flex items-center justify-center text-white font-bold shadow-lg">
            {username?.displayName?.charAt(0) ?? 'U'}
          </div>
          {/* User Dropdown */}
          {showUserDropdown && (
            <div className="absolute right-4 top-20 bg-white p-4 rounded-3xl shadow-lg space-y-2 z-20 w-64">
              <div className="flex items-center space-x-2 border-b pb-2">
                <div className="bg-black rounded-lg w-8 h-8 flex items-center justify-center text-white font-bold">
                  {username?.displayName?.charAt(0) ?? 'U'}
                </div>
                <div>
                  <span className="block font-semibold">{username?.displayName ?? 'User'}</span>
                  <span className="block text-gray-600">{username?.email ?? 'user@email.com'}</span>
                </div>
              </div>
              <button onClick={handleLogOut} className="text-left w-full hover:bg-gray-100 py-1 px-3 rounded-3xl">Log Out</button>
            </div>
          )}
        </div>
        
        {/* Scrollable Content Area */}
        <div className="mt-16 flex-grow overflow-auto p-4">
          {!isUploading && showMessage && (
            <div className="fixed top-4 left-4 p-2 bg-white rounded-lg shadow z-40 flex items-center">
              <FaSpinner className="animate-spin mr-2" />
              <span>Uploading...</span>
            </div>
          )}
          {!isUploading && uploadMessage && showMessage && (
            <div className="fixed top-22 left-13 p-7 bg-white rounded-3xl shadow z-40">
              <span>{uploadMessage}</span>
            </div>
          )}

          {showAddData && (
            <>
              <div className="absolute inset-0 bg-black bg-opacity-30 z-20"></div>
              <div ref={modalRef} className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 bg-white rounded-xl shadow-lg w-[63%] max-w-[639px] z-30 flex flex-col justify-between h-[69%]">
                {/* Section 1: Title, Upload Button, and Close Button */}
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold">Add New Data</h2>
                  <div className="flex items-center">
                    <button className="px-4 py-2 bg-white border text-black rounded-lg hover:bg-black hover:text-white flex items-center justify-center mr-2" onClick={() => fileInputRef.current.click()}>
                      Upload Excel or CSV
                    </button>
                  </div>
                </div>
                <input type="file" ref={fileInputRef} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileChange} multiple style={{display: 'none'}} />

                {/* Section 2: Scrollable List of Files */}
                <div className="flex-grow overflow-auto mt-4">
                  {data.map((fileName, index) => (
                    <label key={index} className="flex items-center justify-between p-2 hover:bg-gray-100 rounded cursor-pointer">
                      <span className="text-gray-900">{fileName}</span>
                      <input 
                        type="checkbox" 
                        className="form-checkbox h-5 w-5 text-blue-600" 
                        checked={checkedFileNames.includes(fileName)}
                        onChange={(e) => handleCheckboxChange(fileName, e.target.checked)}
                      />
                    </label>
                  ))}
                </div>

                {/* Section 3: 'Create Notebook' Button */}
                <div className="flex justify-end mt-4">
                  <button className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800" onClick={handleCreateClick}>
                    Create Journal
                  </button>
                </div>
              </div>
            </>
          )}

          <div className="mb-8 p-4 bg-white rounded-3xl shadow-xl w-[82%] h-[369px] mx-auto">
            <h2 className="text-xl font-semibold">Create a new Journal</h2>
            <p>Create an insight journal to analyze, forecast and turn data points into insights with AI.</p>
            <div className="flex flex-col justify-center mt-11">
              {/* Big square block */}
              <div className="w-[228px] h-[228px] bg-gray-100 flex flex-col justify-center items-center cursor-pointer rounded-3xl hover:bg-gray-200" onClick={handleFetchData}>
                {/* Black circle with white FaPlus */}
                <div className="bg-black w-12 h-12 rounded-full flex justify-center items-center">
                  <FaPlus className="text-white text-3xl" />
                </div>
                {/* Span saying "create" */}
                <span className="text-gray-700 mt-2">Create Journal</span>
              </div>
            </div>          
          </div>

          {/* Projects Table */}
          <div className="rounded-3xl shadow-xl w-[82%] mx-auto overflow-hidden">
            <table className="w-full">
              <thead>
                <tr className="text-left text-gray-600">
                  <th className="p-4">Journals</th>
                  <th className="p-4">Timestamp</th>
                  <th className="p-4"></th>
                </tr>
              </thead>
              <tbody>
                {sortedProjects.map(project => (
                  <tr key={project.id} className="border-t border-gray-200 hover:bg-gray-50 cursor-pointer" onClick={() => handleRowClick(project.id)}>
                    <td className="p-4">{project.name}</td>
                    <td className="p-4">{new Date(project.timestamp).toLocaleString()}</td>
                    <td className="p-4">
                      <button 
                        className="text-gray-500 hover:text-red-600 font-medium" 
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click event
                          deleteProject(project.id); // Call delete function
                        }}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      </div>
      {/* Conditional rendering for non-premium users */}
      
    </div>
  );
};

export default ProjectsPage;
