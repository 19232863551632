import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip';
import './Tips.css';

const AnomalyDetectionChart = ({ chartData, x_axis, y_axis }) => {
  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 800, height: 400 });

  useEffect(() => {
    const observeTarget = wrapperRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height
        });
      });
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, []);

  useEffect(() => {
    if (!chartData || chartData.length === 0) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 30, bottom: 40, left: 60 },
          width = dimensions.width - margin.left - margin.right,
          height = dimensions.height - margin.top - margin.bottom;

    svg.attr('viewBox', `0 0 ${dimensions.width} ${dimensions.height}`)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);
    
    const xScale = d3.scaleLinear()
                    .domain(d3.extent(chartData, d => d.x))
                    .range([0, width]);
    
    const yScale = d3.scaleLinear()
                    .domain(d3.extent(chartData, d => d.y))
                    .range([height, 0]);
    
    // Draw the x-axis at the bottom of the chart
    svg.append('g')
        .attr('transform', `translate(${margin.left},${height + margin.top})`)
        .call(d3.axisBottom(xScale))
        .select('.domain').remove();
    
    // Draw the y-axis to the left of the chart
    svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)
        .call(d3.axisLeft(yScale))
        .select('.domain').remove();
        
    const xAxis = d3.axisBottom(xScale).ticks(12);
    const yAxis = d3.axisLeft(yScale).ticks(12 * height / width);

    // Tooltip setup
    const tip = d3tip()
                .attr('class', 'd3-tip')
                .html(d => `${x_axis}: ${d.x}<br>${y_axis}: ${d.y}<br>Score: ${d.score.toFixed(2)}<br>Label: ${d.label}`);
              
    svg.call(tip);
              

    const densityData = d3.contourDensity()
                          .x(d => xScale(d.x))
                          .y(d => yScale(d.y))
                          .size([width, height])
                          .bandwidth(69)
                          (chartData.filter(d => d.label === 0));

    const colorScale = d3.scaleSequential(d3.interpolateBlues)
                         .domain(d3.extent(densityData, d => d.value));

    svg.append('g')
       .attr('transform', `translate(${margin.left},${margin.top})`)
       .selectAll('path')
       .data(densityData)
       .enter().append('path')
       .attr('d', d3.geoPath())
       .attr('fill', d => colorScale(d.value))
       .attr('stroke', 'none');

    // Append contour to the SVG
    const g = svg.append('g')
                 .attr('transform', `translate(${margin.left},${margin.top})`);

    // Drawing the points
    g.selectAll('path')
    .data(densityData)
    .enter().append('path')
    .attr('d', d3.geoPath())
    .attr('fill', d => colorScale(d.value))
    .attr('stroke', 'none');

    // Ensure the circle's data is used directly for the tooltip
    g.selectAll('circle.visible')
      .data(chartData)
      .enter().append('circle')
      .attr('class', 'visible')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', 6.39)
      .attr('fill', d => d.label === 0 ? 'white' : 'black')
      .attr('stroke', d => d.label === 0 ? 'black' : 'red')
      .attr('stroke-width', d => d.label === 0 ? 1 : 2)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);
    
    g.selectAll('circle.invisible')
      .data(chartData)
      .enter().append('circle')
      .attr('class', 'invisible')
      .attr('cx', d => xScale(d.x))
      .attr('cy', d => yScale(d.y))
      .attr('r', 11)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseover', (event, d) => {
        // This is where you ensure the tooltip uses the original data
        tip.show(d, event.currentTarget);
    })
    .on('mouseout', tip.hide);
  }, [chartData, dimensions]);

  return (
    <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
      <svg ref={svgRef}></svg>
    </div>
  );
};

export default AnomalyDetectionChart;
