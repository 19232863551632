import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { tip as d3tip } from 'd3-v6-tip'; // Import d3-tip
import ResizeObserver from 'resize-observer-polyfill';

const CorrelationScatter = ({ chartData, y_axis, x_axis }) => {
  const svgRef = useRef(null);
  const wrapperRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = wrapperRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        setDimensions({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      });
    });
    resizeObserver.observe(observeTarget);
    return () => resizeObserver.unobserve(observeTarget);
  }, [wrapperRef]);

  useEffect(() => {
    if (!dimensions.width || !dimensions.height) return;

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove();

    const margin = { top: 0, right: 0, bottom: 0, left: 120 };
    const innerWidth = dimensions.width - margin.left - margin.right;
    const innerHeight = dimensions.height - margin.top - margin.bottom;

    svg.attr('width', dimensions.width).attr('height', dimensions.height);

    // Extract feature names for axis labels from chartData
    const featureNamesX = [...new Set(chartData.map(d => d.feature1))];
    const featureNamesY = [...new Set(chartData.map(d => d.feature2))];

    // Use the extracted feature names for xScale domain
    const xScale = d3.scalePoint()
      .domain(featureNamesX)
      .range([margin.left, innerWidth + margin.left])
      .padding(0.69);

    // Use the extracted feature names for yScale domain
    const yScale = d3.scalePoint()
      .domain(featureNamesY)
      .range([innerHeight + margin.top, margin.top])
      .padding(0.69);

    // Add the X Axis with white axis lines and labels
    svg.append('g')
      .attr('transform', `translate(0,${innerHeight + margin.top})`)
      .call(d3.axisBottom(xScale))
      .remove('.domain').attr('stroke', '#ffffff');

    // Add X axis title
    svg.append("text")             
      .attr("transform", `translate(${innerWidth / 2 + margin.left},${innerHeight + margin.top + 40})`)
      .style("text-anchor", "middle")
      .style("fill", "#ffffff");

    // Add the Y Axis with white axis lines and labels
    svg.append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(yScale))
      .select('.domain').attr('stroke', '#ffffff');

    // // Add Y axis title
    // svg.append("text")
    //   .attr("transform", "rotate(-90)")
    //   .attr("y", 0)
    //   .attr("x",0 - (innerHeight / 2 + margin.top))
    //   .attr("dy", "1em")
    //   .style("text-anchor", "middle")
    //   .style("fill", "#ffffff");
    // svg.selectAll('.tick line')
    // .attr('stroke', '#ebebeb');

    const rScale = d3.scaleSqrt()
      .domain([d3.min(chartData, d => Math.abs(d.correlation)), d3.max(chartData, d => Math.abs(d.correlation))])
      .range([3, Math.min(innerWidth, innerHeight) / (2 * Math.sqrt(chartData.length))]);

    // Add the X gridlines
    svg.append('g')   
    .attr('class', 'grid')
    .attr('transform', `translate(0,${innerHeight + margin.top})`)
    .call(d3.axisBottom(xScale)
            .tickSize(-innerHeight)
    )
    .selectAll('.tick line')
    .attr('stroke', '#ebebeb');

    // Add the Y gridlines
    svg.append('g')   
    .attr('class', 'grid')
    .attr('transform', `translate(${margin.left},0)`)
    .call(d3.axisLeft(yScale)
            .tickSize(-innerWidth)
            .tickFormat('')
    )
    .selectAll('.tick line')
    .attr('stroke', '#ebebeb');

    svg.append('g')
    .attr('transform', `translate(${margin.left},0)`)
    .call(d3.axisLeft(yScale).tickSize(0).tickFormat(''))
    .call(g => g.select('.domain').attr('stroke', '#ebebeb'))
    .call(g => g.selectAll('.tick').remove());
    
    // Initialize tooltip
    const tip = d3tip().attr('class', 'd3-tip').html((event, d) => 
      `${d.feature1}<br>${d.feature2}<br>Correlation: ${d.correlation.toFixed(2)}`
    );
    svg.call(tip);

    // Define a color scale that interpolates between red and blue
    const colorScale = d3.scaleSequential()
    .interpolator(d3.interpolateRdBu)
    .domain(d3.extent(chartData, d => d.correlation));

    // Use the color scale for the fill color of the circles
    svg.selectAll('circle')
    .data(chartData)
    .join('circle')
    .attr('cx', d => xScale(d.feature1))
    .attr('cy', d => yScale(d.feature2))
    .attr('r', d => rScale(Math.abs(d.correlation)))
    .attr('fill', d => colorScale(d.correlation)) // Use the color scale here
    .attr('opacity', 0.7)
    .on('mouseover', tip.show)
    .on('mouseout', tip.hide);
    
  }, [chartData, dimensions]);

  return (
    <>
      <style>
        {`
          .d3-tip {
            background: #fff; /* White background */
            border-radius: 8px; /* Rounded corners */
            padding: 10px; /* Padding inside the tooltip */
            color: #333; /* Text color */
            font-size: 14px; /* Adjust the font size as needed */
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); /* Box shadow for a subtle "lifted" effect */
          }
          .d3-tip:after {
            box-sizing: border-box;
            display: inline;
            font-size: 10px;
            width: 100%;
            line-height: 1;
            position: absolute;
            text-align: center;
            top: 100%;
            left: 0;
          }
        `}
      </style>
      <div ref={wrapperRef} style={{ width: '100%', height: '100%' }}>
        <svg ref={svgRef}></svg>
      </div>
    </>
  );
};

export default CorrelationScatter;
