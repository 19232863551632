import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const SuccessPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Function to verify payment and activate subscription
        const verifyPaymentAndActivateSubscription = async (sessionId) => {
            const response = await fetch(`${BASE_URL}/verify-payment-status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sessionId }),
            });

            if (response.ok) {
                const { status, message } = await response.json();
                if (status === 'success') {
                    console.log("Subscription activated successfully.");
                    // Optionally redirect the user or perform additional actions
                } else {
                    console.error(message);
                    alert("There was an issue with your payment. Please check your subscription status.");
                }
            } else {
                console.error("Failed to verify payment status. Please try again.");
            }
        };

        // Extracting session_id from URL
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');

        if (sessionId) {
            verifyPaymentAndActivateSubscription(sessionId);
        } else {
            console.log('No session ID found. Redirecting...');
            navigate('/'); // Redirect to home or another appropriate route
        }
    }, [navigate]);

    return (
        <div className="flex items-center justify-center min-h-screen bg-green-50">
            <div className="text-center">
                <svg className="w-16 h-16 mx-auto text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <h1 className="mt-4 text-3xl font-semibold text-gray-800">Payment Successful!</h1>
                <p className="mt-2 text-gray-600">Thank you for your purchase. Your order is being processed.</p>
                <button 
                onClick={() => navigate('/n')}
                className="px-4 py-2 mt-4 text-sm font-semibold text-green-600 bg-green-100 rounded hover:bg-green-200">Go to Insights Hub</button>
            </div>
        </div>
    );
};

export default SuccessPage;