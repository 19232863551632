import React, { useState, useEffect, useRef } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';
import { BiTrashAlt } from "react-icons/bi";
import { HiOutlineDocumentDuplicate } from "react-icons/hi";
import { AiOutlineFolderAdd, AiOutlineReload, AiOutlinePaperClip } from "react-icons/ai";
import { RenderTable } from './RenderTable';
import Upload from './Upload';

// GA
import { analytics } from '../firebase';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';

const Tri = ({ username, isPremiumUser  }) => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);

  const [showButtons, setShowButtons] = useState(true);
  const [showFiles, setShowFiles] = useState(true);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showCount, setShowCount] = useState('5');
  
  const buttonsRef = useRef(null);

  const [showUpload, setShowUpload] = useState(false);  

  // Add a ref for the hidden file input
  const fileInputRef = useRef(null);
  const [fileList, setFileList] = useState([]);  // State to store the uploaded files

  const [activeButton, setActiveButton] = useState('Analysis');

  const [listData, setListData] = useState({ Chat: [], Analysis: [], Knowledge: [] });
  const [activeFile, setActiveFile] = useState(null);
  const inputRef = useRef(null);
  const fileListRef = useRef(null);

  const [fetchedData, setFetchedData] = useState([]);
  const [sheetNames, setSheetNames] = useState([]);

  // State to handle the visibility of the confirmation dialog
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const [newItemName, setNewItemName] = useState('');

  const [isContainerOpen, setContainerOpen] = useState(false); 
  
  const toggleContainer = () => {
    setContainerOpen(!isContainerOpen); // Toggle the container's visibility
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default behavior
      if (e.shiftKey) {
        // Shift + Enter: Add a new line
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          range.deleteContents(); // Delete selected content if any
          const br = document.createElement("br");
          range.insertNode(br);
          // Move the cursor to the next line
          range.setStartAfter(br);
          range.setEndAfter(br);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      } else {
        // Just Enter: Submit
        handleSend(); // Invoke the send function
      }
    } else if (e.key === '@') {      
      toggleContainer(); // Toggle the visibility of the container
    }
  };

  const buttonClassName = `text-white ml-1 mr-1 h-[42px] w-[42px] flex-shrink-0 rounded-2xl hover:bg-blue-600 transition-all duration-300 ${showButtons ? "bg-blue-600" : "bg-[#333333]"} active:bg-blue-600`;

  // Function to update the position of the [showFiles] container
  const updateFileListPosition = () => {
    if (inputRef.current && fileListRef.current) {
      const inputHeight = inputRef.current.offsetHeight; // Get the height of the input container
      const bottomPosition = inputHeight + 36; // Calculate the new bottom position based on the input height + some offset if needed
      fileListRef.current.style.bottom = `${bottomPosition}px`; // Update the bottom position of the [showFiles] container
    }
  };

  // GA - Start // 
  useEffect(() => {
    analytics.logEvent('page_view', { page: 'homepage' });
  }, []);
  // GA - END //

  useEffect(() => {
    updateFileListPosition(); // Update position on initial render
    // Optionally, add more triggers for updating the position here, e.g., on window resize
  }, [showFiles]); 

  const handlePaste = (e) => {
    e.preventDefault(); // Prevent the default paste action

    // Get the clipboard data in text format
    const text = (e.clipboardData || window.clipboardData).getData('text/plain');

    // Insert the formatted text into the contentEditable div
    const pre = document.createElement("pre");
    pre.textContent = text; // Preserve whitespace and line breaks
    pre.className = "whitespace-pre-wrap break-words"; // Tailwind classes for wrapping and whitespace

    const selection = window.getSelection();
    if (!selection.rangeCount) return false;
    selection.deleteFromDocument(); // Delete the current selection
    const range = selection.getRangeAt(0);
    range.insertNode(pre); // Insert the pre element
    range.setStartAfter(pre); // Move the cursor after the inserted element

    pre.scrollIntoView({ behavior: "smooth", block: "nearest" });

    setInputValue(text);
  };

  const handleFileChange = async (event) => {
    // Store the selected files in the fileList state
    setFileList([...event.target.files]);
  };

  const handleButtonClick = (buttonName) => {
    // Your existing logic for button click (if any)
    setActiveButton(buttonName);
    fetchListData(buttonName);
  };

  // Function to trigger the hidden file input
  const handleFileInputTrigger = () => {
      // handleButtonClick('Clip')
      fileInputRef.current.click();
  };
  
  // State for showing inline input for adding files/folders
  const [showAddInput, setShowAddInput] = useState({ show: false, type: null });

  // Modified handleAddFile and handleAddFolder to show inline input
  const handleAddFile = () => setShowAddInput({ show: true, type: 'file' });
  const handleAddFolder = () => setShowAddInput({ show: true, type: 'folder' });

  const handleAddItem = async () => {
    if (!newItemName) return;
  
    const addType = showAddInput.type; // Use type from showAddInput state
  
    if (addType === 'file') {
      // For file, prepare FormData payload
      const payload = new FormData();
      payload.append('filename', newItemName); // Assuming filename is just a name here, not an actual file
      payload.append('directory', currentDirectory);
      payload.append('userId', username.multiFactor.user.uid);
      const endpoint = `${BASE_URL}/add_file`;
      await sendRequest(payload, endpoint, 'File added successfully.', false);
    } else if (addType === 'folder') {
      // For folder, prepare JSON payload
      const payload = {
        foldername: newItemName,
        directory: currentDirectory,
        userId: username.multiFactor.user.uid,
      };
      const endpoint = `${BASE_URL}/add_folder`;
      await sendRequest(payload, endpoint, 'Folder added successfully.', true);
      }
      
      // Reset the states and refresh the list
      setNewItemName(''); // Reset the newItemName state
      setShowAddInput({ show: false, type: null }); // Hide inline input
      handleRefresh(); // Refresh the list
  };
  
  // Assuming you have a state variable to manage the loading status
  // For example, using useState in a functional component
  const [isLoading, setLoading] = useState(false);

  // Function to handle file uploads
  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;

    setLoading(true); // Start loading

    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file); // 'files' is the key for each file
    });

    formData.append('directory', currentDirectory);
    formData.append('userId', username.multiFactor.user.uid);

    const endpoint = `${BASE_URL}/upload_file`;
    
    try {
      await sendRequest(formData, endpoint, 'Uploaded successfully.');
      handleRefresh(); 
    } catch (error) {
      console.error('Error during file upload:', error);
      // Handle error appropriately
    } finally {
      setLoading(false); // Stop loading regardless of success or failure
      handleRefresh(); 
    }
  };

  const sendRequest = async (payload, url, successMessage, isJson = false) => {
    try {
      const fetchOptions = {
        method: 'POST',
        body: isJson ? JSON.stringify(payload) : payload,
      };
  
      if (isJson) {
        fetchOptions.headers = {
          'Content-Type': 'application/json',
        };
      }
  
      const response = await fetch(url, fetchOptions);
      const data = await response.json();
  
      if (response.ok) {
        handleUploadStatusChange(successMessage);
        // Update your fileList or folderList state here if needed
      } else {
        handleUploadStatusChange(data.message || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error:', error);
      handleUploadStatusChange('An unexpected error occurred.');
    }
  };

  // Function to duplicate a file
  const duplicateFile = async (filename) => {
    const payload = new FormData();
    payload.append('filename', filename);
    payload.append('userId', username.multiFactor.user.uid); // Replace with actual user ID source
    payload.append('directory', currentDirectory); // Include the current directory in the payload

    try {
      const response = await sendRequest(payload, `${BASE_URL}/duplicate`, filename + ' Duplicated successfully.', false);
      
      if (response.ok) {
        // Assuming the response includes the duplicated file's data
        const duplicatedFileData = await response.json();
        // Update UI or state with this new data
      }
    } catch (error) {
      console.error("Error duplicating file:", error);
    }
  };
  
  const deleteFile = async () => {
    setShowConfirmation(false);
  
    const payload = {
      filename: fileToDelete,
      userId: username.multiFactor.user.uid, // Replace with actual user ID source
      directory: currentDirectory, // Include the current directory in the payload
    };
  
    await sendRequest(payload, `${BASE_URL}/delete_file`, fileToDelete+' Deleted successfully.', true);
  };
  
  const handleRefresh = () => {
    fetchListData(activeButton, currentDirectory);
  };

  const fetchListData = async (buttonName, directory = currentDirectory) => {
    try {
      const response = await fetch(`${BASE_URL}/lists?userId=${username.multiFactor.user.uid}&button=${buttonName}&directory=${directory}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();

      // Filter out empty strings and strings containing 'ent_core'
      const filteredData = data.filter(item => item !== '');
        
      setListData(prevData => ({ ...prevData, [buttonName]: filteredData }));
    } catch (error) {
      console.error('Error fetching list data:', error);
    }
  };
  
  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Function to show the confirmation dialog
  const confirmDelete = (file) => {
    setFileToDelete(file);
    setShowConfirmation(true);
  };
    
  const deselectFile = () => {
    setActiveFile(null); // Or however you reset the activeFile in your state
  };

  // Function to remove a file from the fileList
  const removeFile = (fileName) => {
    setFileList(fileList.filter(file => file.name !== fileName));
  };

  useEffect(() => {
    if (showButtons) {
      fetchListData(activeButton);
    }
  }, [showButtons]);
  
  const [currentDirectory, setCurrentDirectory] = useState('data/');
  const [selectIndex, setSelectIndex] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      // Ensure activeFile and currentDirectory are both set
      if (activeFile && currentDirectory) {
        try {
          // Construct your payload
          const payload = {
            filename: activeFile,
            directory: currentDirectory, 
            n: showCount,           
            userId: username.multiFactor.user.uid, // Replace with actual user ID source
          };

          const response = await fetch(`${BASE_URL}/get_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });

          const jsonResponse = await response.json(); // Directly parse the JSON response        

          if (jsonResponse.error) {
            console.error("Server error:", jsonResponse.error);
            return;
          }

          // Assuming 'data' is a JSON string of your actual data
          const parsedData = JSON.parse(jsonResponse.data);

          // Handling sheet names if present
          const sheetNames = jsonResponse.sheet_names ? jsonResponse.sheet_names : [];

          // Here you would set your state or otherwise use the data and sheet names
          setFetchedData(parsedData);
          // Assuming you have a way to handle sheet names in your UI
          setSheetNames(sheetNames);
        } catch (error) {
          console.error('Error fetching data:', error);
          // Optionally, handle the error in the UI as well
        }
      }
    };

    fetchData();
  }, [activeFile, showCount]);

  const handleItemSelected = (item) => {
    if (item.endsWith('/')) { // If it's a folder
      setCurrentDirectory(currentDirectory + item);
      fetchListData(activeButton, currentDirectory + item);
    } else {
      setActiveFile(item);
    }
  };
  
  useEffect(() => {
    fetchListData(activeButton);
  }, [activeButton, currentDirectory]);

  const handleGoBack = () => {
    if (currentDirectory !== 'data/') {
      let pathParts = currentDirectory.split('/');
  
      // Remove the last part (current folder) and the empty string after the last '/'
      pathParts = pathParts.slice(0, -2);
  
      // Join the parts back into a path
      const newDirectory = pathParts.length > 0 ? pathParts.join('/') + '/' : 'data/';
  
      // Update the state and fetch data for the new directory
      setCurrentDirectory(newDirectory);
      fetchListData(activeButton, newDirectory);
    }
  };

  const handleUploadStatusChange = (message) => {
    setMessages(prevMessages => [...prevMessages, { role: 'tri', content: message }]);
  };

  const handleCloseUpload = () => {
      setShowUpload(false);      
  }
  
  const toggleButtons = () => {
    setShowButtons(!showButtons);
    setShowFiles(!showFiles);
  };

  const handleClickOutside = (event) => {
    if (
      (buttonsRef.current && !buttonsRef.current.contains(event.target)) &&
      (fileListRef.current && !fileListRef.current.contains(event.target))
    ) {
      setShowButtons(false);
      setShowFiles(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleSend = async (givenValue = inputValue) => {
    const messageContent = givenValue;

    // Clear the input field immediately after getting the message content
    if (inputRef.current) {
      inputRef.current.textContent = '';
    }
    setInputValue('');

    if (!messageContent.trim()) return;

    // Immediately add the user's message to the state
    setMessages(prevMessages => [...prevMessages, { role: 'user', content: messageContent }]);

    const formData = new FormData();
    if (activeFile) {
      formData.append('filename', activeFile);
    }
    formData.append('feature', activeButton);
    formData.append('prompt', messageContent);
    formData.append('directory', currentDirectory);
    formData.append('username', username.multiFactor.user.displayName);
    formData.append('userId', username.multiFactor.user.uid);
    fileList.forEach(file => formData.append('files', file));

    // Immediately remove file from on upload    
    setFileList(prevFileList => prevFileList.filter(file => file !== fileList[0]));
    
    try {
      const response = await fetch(`${BASE_URL}/tri`, {
        method: 'POST',
        body: formData,  // Send formData instead of JSON
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      const excludeList = [ 'suggestion', 'suggest', 'prompt', 'recommend'];
      const regexPattern = new RegExp(excludeList.join('|'), 'gi');
      const parsedString = responseData.recommend_output
        .toLowerCase().replace(regexPattern, '')
        .replace('/[\[\]]/g', '')
        .split(/(?=\d\.)/)
        .map(item => item.trim())
        .filter(Boolean);

      // Initialize an array with the first message.
      const messagesToAdd = [
        { role: 'tri', content: responseData.gpt_response }
      ];

      // If parsedString is not empty, add the second message.
      if (parsedString.length > 0) {
        messagesToAdd.push({ role: 'tri', content: parsedString });
      }

      setMessages(prevMessages => [
        ...prevMessages,
        ...messagesToAdd
      ]);      

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

const [isEmpty, setIsEmpty] = useState(true);
const handleInput = (e) => {
  setInputValue(e.currentTarget.textContent);
  // setIsEmpty(!e.currentTarget.textContent.trim());
};

const handleFocus = () => {
  if (isEmpty) {
    setIsEmpty(false);
  }
};

const Message = ({ role, content }) => {
  // Function to determine the title based on role and content type
  const getTitle = () => {
    if (role === 'user') {
      return <>{username?.displayName?.split(' ')[0] ?? 'User'}</>;
    } else if (role === 'tri' && Array.isArray(content)) {
      return 'Suggested follow-ups';
    } else if (role === 'tri') {
      return <img src="logo.svg" alt="Try AI, Tri AI" className="w-7 h-7" />;
    }
    return 'User Message'; // Default title, adjust as necessary
  };

  // Function to safely create markup for strings
  const createMarkup = (text) => ({ __html: text });
  
  const renderContent = () => {
    if (Array.isArray(content)) {
      // Render buttons for array content with updated styles
      return (
        <div className="flex flex-row flex-wrap justify-start">
          {content.map((item, index) => (
            <button
              key={index}
              className="my-2 p-2 bg-white text-blue-500 border border-blue-500 rounded-3xl"
              style={{ textAlign: 'left' }} // Ensuring text aligns left
              onClick={() => handleSend(item)} // Correctly use an arrow function to delay execution
            >
              {item}
            </button>
          ))}
        </div>
      );
    } else if (typeof content === 'string') {
      // Handle string content
      const formattedContent = content
        // .replace(/['"]+/g, '')
        .replace(/\\n/g, '<br />')
        .replace(/\\(?!n|u[\d\w]{4}|")/g, "'");
      return <div dangerouslySetInnerHTML={createMarkup(formattedContent)} />;
    }
  };

  // Adjust messageClasses based on role and content type
  const messageClasses = (() => {
    if (role === 'user') {
      return 'w-full max-w-[82%] text-lg p-4 my-2 rounded-3xl mb-13 mx-auto bg-white'; // White background for 'api2'
    } else if (role === 'tri' && Array.isArray(content)) {
      return 'w-full max-w-[82%] text-lg p-4 my-2 rounded-3xl mb-13 mx-auto'; // Special styling for 'api' string2
    } else if (role === 'tri') {
      return 'w-full max-w-[82%] text-lg p-4 my-2 rounded-3xl mb-13 mx-auto bg-blue-100'; // Blue background for 'api' string1
    }
  })();

  // Render the message with a title
  return (
    <div className={messageClasses}>
      <div className="font-bold text-md">{getTitle()}</div> {/* Render the dynamic title */}
      {renderContent()}
    </div>
  );
};

return (
      <div className="min-h-screen flex flex-col bg-white pt-7">
        {/* Conditionally render logo and H1 if messages array is empty */}
        {messages.length === 0 ? (
          <div className="flex-grow flex flex-col items-center justify-center">
            {/* Replace "logo.svg" with the path to your actual logo */}
            <img src="logo.svg" alt="Logo" className="w-28 h-28" />
            <h1 className="text-2xl font-bold mt-3">Insights with AI</h1>
          </div>
        ) : (
          <div className="flex-grow bg-opacity-20 sm:p-1 rounded-2xl mt-16 mb-16 overflow-y-auto relative mx-auto sm:w-[82%] md:w-[83%]">
            {messages.map((message, index) => (
              <Message key={index} role={message.role} content={message.content} />
            ))}
          </div>
        )}

    <Upload isVisible={showUpload} onClose={handleCloseUpload} onUploadStatusChange={handleUploadStatusChange} username={username} />

    {/* Active File Container */}
    {activeFile && (
    <div className={`fixed top-[7px] left-0 right-0 mx-auto w-full max-w-[82%] md:max-w-[69%] bg-white shadow-md p-3 text-center rounded-2xl ${isMinimized ? 'minimized-class' : ''}`}>
      <div className="flex justify-between items-center">
        <span className='text-xl'>
          {activeFile.includes(" :: ") ? activeFile.split(' :: ')[0].trim() : activeFile}
        </span>        
        <div>
          <button 
            className="text-gray-700 hover:text-red-500 text-xl mx-2"
            onClick={toggleMinimize}
          >
            {isMinimized ? '+' : '-'}
          </button>
          <button 
            className="text-gray-700 hover:text-red-500 text-xl"
            onClick={deselectFile}
          >
            &times;
          </button>            
        </div>
      </div>
      
      {sheetNames && sheetNames.length > 0 && (
          <div className="flex space-x-2 mt-2">
            {sheetNames.map((sheet, index) => {
              // Determine if the current sheet is the active one without modifying the activeFile directly
              const filename = activeFile.includes(" :: ") ? activeFile.split(' :: ')[0].trim() : activeFile;
              const isActiveSheet = activeFile.split(' :: ')[1]?.trim() === sheet;
              
              // Dynamically set button classes based on active status
              const buttonClasses = isActiveSheet
                ? "my-2 p-2 bg-blue-500 text-white border border-blue-500 rounded-3xl" // Highlighted style for active sheet
                : "my-2 p-2 bg-white text-blue-500 border border-blue-500 rounded-3xl"; // Default style

              return (
                <button
                  key={index}
                  className={buttonClasses}
                  onClick={() => setActiveFile(`${filename} :: ${sheet}`)} // Concatenate filename with selected sheet
                >
                  {sheet}
                </button>
              );
            })}
          </div>
      )}

        {/* Conditional rendering of the table based on isMinimized */}
        {!isMinimized && (
          <div className="mt-6">
            <RenderTable fetchedData={fetchedData} />
          </div>
        )}

        {!isMinimized && (
          <div className="flex space-x-2 mt-2">            
            {['5', '25', '100', 'last 5', 'last 25', 'last 100'].map((n, index) => {              
              const isCount = showCount === String(n);
              
              // Dynamically set button classes based on active status
              const buttonClasses = isCount
                ? "my-2 p-2 bg-blue-500 text-white border border-blue-500 rounded-3xl" // Highlighted style for active sheet
                : "my-2 p-2 bg-white text-blue-500 border border-blue-500 rounded-3xl"; // Default style

              return (
                <button
                  key={index}
                  className={buttonClasses}
                  onClick={() => setShowCount(`${n}`)}
                >
                  {n}
                </button>
              );
            })}
          </div>
        )}

      </div>    
    )}
    
    <div className="fixed bottom-3 left-0 right-0 pb-4">
      <div className="flex items-center justify-center border rounded-2xl bg-white shadow-lg mt-2 relative w-full mx-auto max-w-[82%] md:max-w-[69%]">
          
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            multiple
          />

          {/* Toggle button */}
          <button
              className={buttonClassName}
              onClick={toggleButtons}
              style={{marginRight: '1rem'}}
          >Data
          </button>

          <div>
            {isLoading ? (
              // Show loading with blur background when isLoading is true
              <div className="absolute bottom-[56px] left-0 right-0 mx-auto max-w-[82%] md:max-w-[69%] border rounded-2xl bg-white shadow-md p-3 bg-opacity-50 backdrop-blur-sm">
                <div className="loading-wheel animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
              </div>
            ) : showFiles ? (
              <div ref={fileListRef} className="fixed bottom-[96px] left-0 right-0 pb-4">
                {['Analysis'].includes(activeButton) && (
                  <div className="absolute bottom-0 left-0 right-0 mx-auto max-w-[82%] md:max-w-[69%] border rounded-2xl bg-white shadow-md p-3">

                  {/* Inline input for adding file/folder */}
                  {showAddInput.show && (
                    <div className="flex items-center mb-3">
                      <input
                        type="text"
                        className="border p-2 flex-grow"
                        placeholder={`Enter ${showAddInput.type} name...`}
                        value={newItemName}
                        onChange={(e) => setNewItemName(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleAddItem()} // Add on Enter key press
                      />
                      <button onClick={handleAddItem} className="ml-2 text-blue-500"><FaCheck /></button>
                      <button onClick={() => setShowAddInput({ show: false, type: null })} className="ml-2 text-red-500"><FaTimes /></button>
                    </div>
                  )}

                  {/* Top part: 'Back' Button on the far left and File/Folder Icons on the far right */}
                  <div className="flex justify-between mb-3">
                    {/* Conditional rendering of 'Back' Button */}
                    {currentDirectory !== 'data/' && (
                      <button onClick={handleGoBack} className="text-gray-700 text-lg p-1 mx-1 my-1">
                        Back
                      </button>
                    )}

                    {/* Spacer for aligning buttons to the far left and right */}
                    {currentDirectory === 'data/' && <div></div>}

                    {/* File and Folder Add Icons on the right */}
                    <div>
                      <button onClick={handleRefresh} className="text-gray-700 text-lg p-1 mx-1 my-1">                        
                        <AiOutlineReload />
                      </button>
                      <button onClick={handleAddFolder} className="text-gray-700 text-xl p-1 mx-1 my-1">
                        <AiOutlineFolderAdd />
                      </button>
                      <button onClick={handleFileInputTrigger} className="text-gray-700 text-xl p-1 mx-1 my-1">
                        <AiOutlinePaperClip />
                      </button>
                    </div>
                  </div>

                  {/* Bottom part: List of Files */}
                  <div>
                    {listData[activeButton].map((item, index) => (
                      <div key={index} className={`flex justify-between items-center rounded-full p-2 group hover:bg-gray-100 ${activeFile === item ? 'bg-blue-100' : ''}`}>
                        <span onClick={() => handleItemSelected(item)} className="flex-grow text-left">
                          {item}
                        </span>
                        {!item.endsWith('/') && (
                          <button onClick={() => duplicateFile(item)} className="opacity-0 group-hover:opacity-100 text-gray-500 hover:text-gray-700 mr-3">
                            <HiOutlineDocumentDuplicate />
                          </button>
                        )}
                        <button onClick={() => confirmDelete(item)} className="opacity-0 group-hover:opacity-100 text-red-500 hover:text-red-700 mr-3">
                          <BiTrashAlt />
                        </button>
                      </div>
                    ))}
                  </div>

                </div>
              )}
            </div>
            ) : null}
          </div>                
          
          {/* Confirmation Dialog */}
          {showConfirmation && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-4 rounded-lg text-center">
                <p>Are you sure you want to delete <span className="font-bold">{fileToDelete}</span> permanently?</p>
                <br></br>
                <p className='text-sm text-red-500'>*All items inside folders will be deleted also.</p>                                
                <div className="flex justify-around mt-4">
                  <button onClick={() => setShowConfirmation(false)} className="bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded">
                    Cancel
                  </button>
                  <button onClick={deleteFile} className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded">
                    Yes, Delete
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* New buttons container */}
          {showButtons && (
              <div ref={buttonsRef} className="absolute top-[-56px] left-0 flex justify-center space-x-2">              
                  {/* <button onClick={() => handleButtonClick('Analysis')} className={`btn border p-3 rounded-full hover:bg-blue-600 hover:text-white ${activeButton === 'Analysis' ? 'bg-blue-600 text-white' : 'bg-white'}`}>Data</button> */}
                  {/* <button onClick={() => handleButtonClick('Chat')} className={`btn border p-3 rounded-full hover:bg-blue-600 hover:text-white ${activeButton === 'Chat' ? 'bg-blue-600 text-white' : 'bg-white'}`} >Chat</button> */}
                </div>
          )}
        
          {/* Input Container */}
          <div className="flex flex-col items-left justify-left w-full">
          {/* File Display Section */}
          {fileList.length > 0 && (
            <div className="relative mb-2">
              <div className="bg-gray-100 text-gray-700 text-sm mt-2 mb-2 px-4 py-2 rounded-md shadow w-[53%] flex justify-between items-center">
                <span>{fileList[0].name}</span> {/* Display the first file name */}
                <button 
                  className="text-gray-700 hover:text-red-500" 
                  onClick={() => removeFile(fileList[0].name)}
                >
                  &times; 
                </button>
              </div>
            </div>
          )}

          <div ref={inputRef} 
              className="flex-grow p-3 left-1 rounded-r-2xl relative focus:outline-none max-w-prose overflow-auto"
              contentEditable={true}
              style={{ minHeight: 'calc(7vh)', maxHeight: 'calc(28vh)', overflowY: 'auto', overflowX: 'hidden' }}
              onFocus={handleFocus}
              onInput={handleInput}
              onPaste={handlePaste}
              onKeyDown={onKeyDown}
              >
            {isEmpty && <span className="absolute p-2 top-1 left-1 text-gray-400">Just Ask Tri</span>}
          </div>
        </div>

        
        {/* Send button */}
        <button 
            className="bg-blue-500 text-white p-2 ml-1 mr-1 rounded-full hover:bg-blue-600 transition-all duration-300 active:shadow-outline-blue active:scale-95"
            onClick={handleSend}
        >
            <FiCheck size={18}/>
        </button>
      </div>
    </div>

    </div>
  );
}

export default Tri;