import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import AppLayout from '../AppLayout';
import './Paper.css';

import { SlOptionsVertical } from "react-icons/sl";
import { IoMdAdd } from 'react-icons/io';
import CreateXPlot from './CreateXPlot';
import CreateYPlot from './CreateYPlot';
import CreateZPlot from './CreateZPlot';

// GA
import { analytics } from '../../firebase';

// const BASE_URL = 'https://api.triangl.ai'; 
const BASE_URL = 'http://localhost:5000';

const App = ({username}) => {
  const [hoveredLine, setHoveredLine] = useState(null);
  const [lines, setLines] = useState([
    "Click anywhere and just start typing",
    "Hit / for more",
  ]);
  const [showDropdown, setShowDropdown] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputPlaceholder, setInputPlaceholder] = useState("Type here...");
  const [isLoading, setIsLoading] = useState(false);
  const [selection, setSelection] = useState("");
  const [plots, setPlots] = useState({}); // State to manage which plot to display in each line
  const inputRef = useRef(null);

  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState('');
  const { notebookId } = useParams(); // Access route parameters

  useEffect(() => {
    const updateEmptyRows = () => {
      const rowHeight = 33;
      const totalHeight = window.innerHeight;
      const usedHeight = lines.length * rowHeight;
      const emptyRowCount = Math.max(0, Math.ceil((totalHeight - usedHeight) / rowHeight));

      setLines(prevLines => {
        const newLines = [...prevLines];
        while (newLines.length < prevLines.length + emptyRowCount) {
          newLines.push("");
        }
        return newLines;
      });
    };

    updateEmptyRows();
    window.addEventListener('resize', updateEmptyRows);

    return () => window.removeEventListener('resize', updateEmptyRows);
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    const index = lines.findIndex(line => line === "Hit / for more");
    if (index !== -1) {
      setShowDropdown(index);
    }
  }, [lines]);

  useEffect(() => {
    if (showDropdown !== null && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showDropdown]);

  const addNewLine = (index) => {
    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines.splice(index + 1, 0, "");
      return newLines;
    });

    setTimeout(() => {
      const newLineElement = document.querySelectorAll('.line')[index + 1];
      if (newLineElement) {
        newLineElement.querySelector('[contenteditable]').focus();
      }
    }, 0);
  };

  const deleteLine = (index) => {
    setLines(prevLines => {
      const newLines = [...prevLines];
      if (newLines.length > 1) {
        newLines.splice(index, 1);
      }
      return newLines;
    });

    setTimeout(() => {
      if (index > 0) {
        const previousLineElement = document.querySelectorAll('.line')[index - 1];
        if (previousLineElement) {
          previousLineElement.querySelector('[contenteditable]').focus();
        }
      }
    }, 0);
  };

  const updateLine = (index, html) => {
    setLines(prevLines => {
      const newLines = [...prevLines];
      newLines[index] = html;
      return newLines;
    });
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addNewLine(index);
    } else if (e.key === "Backspace" && e.target.innerText === "") {
      e.preventDefault();
      deleteLine(index);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      if (index > 0) {
        document.querySelectorAll('.line')[index - 1].querySelector('[contenteditable]').focus();
      }
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      if (index < lines.length - 1) {
        document.querySelectorAll('.line')[index + 1].querySelector('[contenteditable]').focus();
      }
    } else if (e.key === "/" && e.target.innerText === "") {
      setShowDropdown(index);
    } else {
      setShowDropdown(null);
    }
  };

  const handleInputKeyDown = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setIsLoading(true);
      // Simulate fetching data
      setTimeout(() => {
        // const sampleText = `Sample text from ${selection.replace('_', ' ')}`;
        const sampleText = `John's profile shows significant growth over the past few years, with some dips along with the market downturn. It's to be expected but some additional risk management would be good to consider here.`;
        setLines(prevLines => {
          const newLines = [...prevLines];
          newLines[index] = sampleText || "No data received";
          for (let i = 0; i < 6; i++) {
            newLines.splice(index + 1 + i, 0, "plot");
          }
          return newLines;
        });
        setPlots(prevPlots => ({ ...prevPlots, [index + 1]: selection })); // Associate the plot with the new line
        setIsLoading(false);
        setShowDropdown(null);
        setInputValue("");
        setInputPlaceholder("Type here...");
      }, 1000); // Simulate network delay
    }
  };

  const handleDropdownSelect = (option, index) => {
    setShowDropdown(index);
    setSelection(option);
    switch(option) {
      case 'create_x':
        setInputPlaceholder('Create X for...');
        break;
      case 'create_y':
        setInputPlaceholder('Create Y for...');
        break;
      case 'create_z':
        setInputPlaceholder('Create Z for...');
        break;
      default:
        setInputPlaceholder('Type here...');
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const renderPlot = (index) => {
    switch (plots[index]) {
      case 'create_x':
        return <CreateXPlot />;
      case 'create_y':
        return <CreateYPlot />;
      case 'create_z':
        return <CreateZPlot />;
      default:
        return null;
    }
  };

  // AppLayout
  const toggleEdit = () => {
    analytics.logEvent('edit_notebook_title', { page: 'notebook' });
    if (isEditing) {
      updateTitle(title);
    }
    setIsEditing(!isEditing);
  };

  // Renders the title or an input field based on editing mode
  const renderTitle = () => {
    if (isEditing) {
      return (
        <input
          type="text"
          value={title}
          onChange={handleTitleChange}
          onBlur={toggleEdit}
          autoFocus
          className="bg-gray-100 p-1"
        />
      );
    }

    return <h1 onClick={toggleEdit} className="cursor-pointer">{title}</h1>;
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const updateTitle = async (newTitle) => {
    await fetch(`${BASE_URL}/notebook-title`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        { 
          userId: username.multiFactor.user.uid,
          notebookId: notebookId, 
          title: newTitle,
        }),
    });
  };

  return (
    <AppLayout 
      title={renderTitle()}
      username={username}
    >
    <div className="app p-4 min-h-screen flex flex-col">
      <h1 className='text-[48px] pt-24 ml-[40px] mb-3'>Generative Blogs</h1>
      <p className='text-[36px] ml-[40px]'>👋 Write amazing blogs!</p>
      <div className="content flex-grow mt-4">
        <div className="content-editable-container">
          {lines.map((line, index) => (
            <div
              key={index}
              className={`line relative pl-8 mb-2 ${line === 'plot' ? 'h-80 non-editable' : 'h-8'} flex items-center text-base`}
              onMouseEnter={() => setHoveredLine(index)}
              onMouseLeave={() => setHoveredLine(null)}
            >
              {hoveredLine === index && line !== 'plot' && (
                <span className="icons absolute left-0 transform flex gap-1">
                  <div className="tooltip">
                    <IoMdAdd className="icon cursor-pointer" onClick={(e) => { e.stopPropagation(); addNewLine(index); }} />
                    <span className="tooltiptext">Add Below</span>
                  </div>
                </span>
              )}
              {showDropdown === index ? (
                <div className="relative w-full">
                  <textarea
                    ref={inputRef}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={(e) => handleInputKeyDown(e, index)}
                    className="w-full rounded-xl border border-[#e8c6ff] p-2"
                    placeholder={inputPlaceholder}
                    rows={1}
                    style={{ overflow: 'hidden', resize: 'none', outline: 'none' }}
                    onFocus={(e) => (e.target.style.borderColor = '#7832ff')}
                    onBlur={(e) => (e.target.style.borderColor = '#e8c6ff')}
                  />
                  <div className="dropdown absolute top-full left-0 mt-1">
                    <div onClick={() => handleDropdownSelect('create_x', index)}>Create X</div>
                    <div onClick={() => handleDropdownSelect('create_y', index)}>Create Y</div>
                    <div onClick={() => handleDropdownSelect('create_z', index)}>Create Z</div>
                  </div>
                  {isLoading && <div className="absolute top-full left-0 mt-1">Loading...</div>}
                </div>
              ) : line === 'plot' ? (
                <div className="w-full h-full">
                  {renderPlot(index)}
                </div>
              ) : (
                <div
                  contentEditable
                  suppressContentEditableWarning
                  dangerouslySetInnerHTML={{ __html: line }}
                  onBlur={(e) => updateLine(index, e.target.innerHTML)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  className="w-full h-8 flex items-center"
                ></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
    </AppLayout>
  );
};

export default App;