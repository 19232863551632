import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import html2canvas from 'html2canvas';

const BASE_URL = 'https://api.triangl.ai'; 
// const BASE_URL = 'http://localhost:5000';
const BASE_REDIRECT_URL = 'https://triangl.ai/qr';
// const BASE_REDIRECT_URL = 'http://localhost:3000/qr';

const LinkedInLogin = () => {
  const [userName, setUserName] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const profileRef = useRef(null);

  const handleLogin = async () => {
    const response = await fetch(`${BASE_URL}/config/linkedin-client-id`);
    const data = await response.json();
    const client_id = data.client_id;
    const redirect_uri = `${BASE_REDIRECT_URL}`;
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${client_id}&redirect_uri=${redirect_uri}`;

    window.location.href = authUrl;
  };

  const handleCallback = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (code) {
      try {
        const tokenResponse = await fetch(`${BASE_URL}/oauth/linkedin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            code,
            redirect_uri: `${BASE_REDIRECT_URL}`
          })
        });

        if (!tokenResponse.ok) {
          const errorText = await tokenResponse.text();
          console.error('Token fetch failed:', errorText);
          throw new Error(`Failed to fetch access token: ${tokenResponse.statusText}, ${errorText}`);
        }

        const tokenData = await tokenResponse.json();
        const idToken = tokenData.id_token;

        const base64Url = idToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        const user = JSON.parse(jsonPayload);
        const { name, given_name, family_name, picture, email } = user;
        setUserName(`${given_name} ${family_name}`);
        setProfilePicture(picture);

        const searchResponse = await fetch(`${BASE_URL}/search/linkedin-profile`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ name: `${given_name} ${family_name}` })
        });

        if (searchResponse.ok) {
          const searchData = await searchResponse.json();
          setProfileUrl(searchData.profile_url);
        } else {
          console.error('Profile search failed');
        }
      } catch (error) {
        console.error('Error during LinkedIn OAuth process:', error);
      }
    } else {
      console.log('No authorization code found');
    }
  };

  const handleSaveWallpaper = () => {
    const appElement = document.querySelector('.app');
    const saveButton = document.getElementById('saveButton');

    if (appElement && saveButton) {
      saveButton.style.display = 'none';

      html2canvas(appElement, {
        useCORS: true,
        onclone: (documentClone) => {
          const cloneImg = documentClone.querySelector('img');
          if (cloneImg) {
            cloneImg.crossOrigin = 'anonymous';
          }
        },
      }).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'profile_wallpaper.png';
        document.body.appendChild(link);

        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
          link.click();
          setTimeout(() => {
            document.body.removeChild(link);
            saveButton.style.display = 'block';
          }, 100);
        } else {
          link.click();
          saveButton.style.display = 'block';
        }
      });
    }
  };

  React.useEffect(() => {
    handleCallback();
  }, []);

  return (
    <div className="app flex flex-col items-center justify-center min-h-screen">
      {userName ? (
        <div className="flex flex-col items-center justify-center w-full md:w-2/5 p-4" ref={profileRef}>
          {profilePicture && <img src={profilePicture} alt="Profile" className="mb-4 rounded-full" crossOrigin="anonymous" />}
          <h1 className="text-xl font-bold mb-4">{userName}</h1>
          {profileUrl && (
            <div className="text-center">
              <QRCode value={profileUrl} size={169} />
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="text-center p-5 text-gray-800">
            <h1 className="text-4xl font-bold mb-3 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-indigo-500 animate-bounce">
              10X Your Network
            </h1>
            <h2 className="text-2xl font-semibold mb-7">Effortless, Meaningful Connections</h2>
            <p className="text-lg mb-5">Connect seamlessly with Smart QR.</p>
          </div>
          <button
            onClick={handleLogin}
            className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Sign in with LinkedIn
          </button>
        </>
      )}
      {userName && (
        <button
          onClick={handleSaveWallpaper}
          id="saveButton"
          className="mt-4 px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
        >
          Save Wallpaper
        </button>
      )}
    </div>
  );
};

export default LinkedInLogin;



// const LinkedInLogin = () => {
//   const [userName, setUserName] = useState(null);
//   const [profilePicture, setProfilePicture] = useState(null);
//   const [profileUrl, setProfileUrl] = useState(null);
//   const profileRef = useRef(null);

//   const handleLogin = async () => {
//     const response = await fetch(`${BASE_URL}/config/linkedin-client-id`);
//     const data = await response.json();
//     const client_id = data.client_id;
//     const redirect_uri = `${BASE_REDIRECT_URL}`;
//     const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&state=987654321&scope=openid%20profile%20email&client_id=${client_id}&redirect_uri=${redirect_uri}`;

//     window.location.href = authUrl;
//   };

//   const handleCallback = async () => {
//     const params = new URLSearchParams(window.location.search);
//     const code = params.get('code');

//     if (code) {
//       try {
//         const tokenResponse = await fetch(`${BASE_URL}/oauth/linkedin`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//           },
//           body: new URLSearchParams({
//             code,
//             redirect_uri: `${BASE_REDIRECT_URL}`
//           })
//         });

//         if (!tokenResponse.ok) {
//           const errorText = await tokenResponse.text();
//           console.error('Token fetch failed:', errorText);
//           throw new Error(`Failed to fetch access token: ${tokenResponse.statusText}, ${errorText}`);
//         }

//         const tokenData = await tokenResponse.json();
//         const idToken = tokenData.id_token;

//         const base64Url = idToken.split('.')[1];
//         const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//         const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
//           return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//         }).join(''));

//         const user = JSON.parse(jsonPayload);
//         const { name, given_name, family_name, picture, email } = user;
//         setUserName(`${given_name} ${family_name}`);
//         setProfilePicture(picture);

//         const searchResponse = await fetch(`${BASE_URL}/search/linkedin-profile`, {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify({ name: `${given_name} ${family_name}` })
//         });

//         if (searchResponse.ok) {
//           const searchData = await searchResponse.json();
//           setProfileUrl(searchData.profile_url);
//         } else {
//           console.error('Profile search failed');
//         }
//       } catch (error) {
//         console.error('Error during LinkedIn OAuth process:', error);
//       }
//     } else {
//       console.log('No authorization code found');
//     }
//   };

//   const handleSaveWallpaper = () => {
//     if (profileRef.current) {
//       const img = profileRef.current.querySelector('img');
//       if (img) {
//         img.crossOrigin = 'anonymous';
//       }

//       html2canvas(profileRef.current, {
//         useCORS: true,
//         onclone: (documentClone) => {
//           const cloneImg = documentClone.querySelector('img');
//           if (cloneImg) {
//             cloneImg.crossOrigin = 'anonymous';
//           }
//         },
//       }).then((canvas) => {
//         const link = document.createElement('a');
//         link.href = canvas.toDataURL('image/png');
//         link.download = 'profile_wallpaper.png';
//         link.click();
//       });
//     }
//   };

//   React.useEffect(() => {
//     handleCallback();
//   }, []);

//   return (
//     <div className="flex flex-col items-center justify-center min-h-screen">
//       {userName ? (
//         <div className="flex flex-col items-center justify-center w-full md:w-2/5 p-4 border border-gray-300 rounded-lg shadow-lg" ref={profileRef}>
//           <h1 className="text-2xl font-bold mb-4">{userName}</h1>
//           {profilePicture && <img src={profilePicture} alt="Profile" className="mb-4 rounded-full" />}
//           {profileUrl && (
//             <div className="text-center">
//               <QRCode value={profileUrl} size={256} />
//             </div>
//           )}
//         </div>
//       ) : (
//         <>
//           <div className="text-center p-5 text-gray-800">
//             <h1 className="text-4xl font-bold mb-3 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-indigo-500 animate-bounce">
//               10X Your Network
//             </h1>
//             <h2 className="text-2xl font-semibold mb-7">Effortless, Meaningful Connections</h2>
//             <p className="text-lg mb-5">Connect seamlessly with Smart QR.</p>
//           </div>
//           <button
//             onClick={handleLogin}
//             className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
//           >
//             Sign in with LinkedIn
//           </button>
//         </>
//       )}
//       {userName && (
//         <button
//           onClick={handleSaveWallpaper}
//           className="mt-4 px-6 py-3 bg-green-600 text-white rounded-lg shadow-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
//         >
//           Save Wallpaper
//         </button>
//       )}
//     </div>
//   );
// };

// export default LinkedInLogin;

