import React, { useEffect } from 'react';
import * as d3 from 'd3';

const CreateYPlot = () => {
  useEffect(() => {
    const data1 = Array.from({ length: 100 }, (_, i) => ({ date: new Date(2020, 0, i + 1), value: Math.random() * 100 }));
    const data2 = Array.from({ length: 100 }, (_, i) => ({ date: new Date(2020, 0, i + 1), value: Math.random() * 100 }));

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 480 - margin.left - margin.right;
    const height = 240 - margin.top - margin.bottom;

    const svg = d3.select('#y-plot')
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x = d3.scaleTime()
      .domain(d3.extent(data1, d => d.date))
      .range([0, width]);

    const y = d3.scaleLinear()
      .domain([0, 100])
      .range([height, 0]);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    svg.append('g')
      .call(d3.axisLeft(y));

    svg.append('path')
      .datum(data1)
      .attr('fill', 'none')
      .attr('stroke', 'green')
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .x(d => x(d.date))
        .y(d => y(d.value))
      );

    svg.append('path')
      .datum(data2)
      .attr('fill', 'none')
      .attr('stroke', 'red')
      .attr('stroke-width', 1.5)
      .attr('d', d3.line()
        .x(d => x(d.date))
        .y(d => y(d.value))
      );

  }, []);

  return <div id="y-plot"></div>;
};

export default CreateYPlot;
