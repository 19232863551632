import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { Link } from 'react-router-dom';
import logo from './trianglai-icon.svg';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import * as firebaseui from 'firebaseui';
import "firebaseui/dist/firebaseui.css";
import { FcGoogle } from 'react-icons/fc';

const SignUp = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const uiConfig = {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: () => {
          navigate('/n');
          return false;
        },
      },
    };

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.start('#firebaseui-auth-container', uiConfig);
  }, [navigate]);

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      await firebase.auth().signInWithPopup(provider);
      navigate('/n');
    } catch (error) {
      console.error(error);
    }
  };
  
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState({ name: '', email: '', password: '' });
  const [checked, setChecked] = useState(true);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  
  const handleChange = e => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const validatePassword = (password) => {
    return password.length >= 8; // Checks if password is at least 8 characters long
  }

  const handleNext = e => {
    e.preventDefault();
    
    if (!validateEmail(userData.email)) {
      setEmailError('Please enter a valid email.');
      return;
    }

    if (!validatePassword(userData.password)) {
      setPasswordError('Password must be at least 8 characters long.');
      return;
    }

    setStep(2);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const userCredential = await auth.createUserWithEmailAndPassword(userData.email, userData.password);
      await userCredential.user.updateProfile({
        displayName: userData.name,
      });
      navigate('/tri');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col w-full md:w-1/2 lg:w-1/3 mx-auto bg-white p-8 my-10 rounded-3xl shadow-lg">
      <div className="text-center mb-8">
        <img className="mx-auto h-16 w-auto" src={logo} alt="Company Logo" />
        <h2 className="mt-2 text-center text-2xl font-bold text-gray-900">Join TriangleAI Today</h2>
      </div>
      
      {step === 0 && (
        <>
          <div id="firebaseui-auth-container"></div>
          <button 
            onClick={signInWithGoogle} 
            className="w-full bg-gray-100 text-[#3D4043] flex justify-center items-center text-xl py-3 rounded-full mt-4 mb-4"
          >
            <FcGoogle className="mr-2" />
            Sign up with Google
          </button>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-300"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-white text-gray-500">or</span>
            </div>
          </div>
          <button onClick={() => setStep(1)} className="w-full bg-indigo-500 text-white text-xl py-3 rounded-full mt-4">Create account</button>
        </>
      )}

      {step === 1 && (
        <form onSubmit={handleNext}>
          <h2 className="text-2xl font-semibold mb-4">Create your account</h2>
          <input className="mt-2 mb-4 w-full p-3 border border-gray-300 rounded-lg" type="text" name="name" placeholder="Username" onChange={handleChange} required/>
          <input className="mt-2 mb-4 w-full p-3 border border-gray-300 rounded-lg" type="email" name="email" placeholder="Email" onChange={handleChange} required/>
          {emailError && <p className="text-red-500 text-sm">{emailError}</p>}
          <input className="mt-2 mb-4 w-full p-3 border border-gray-300 rounded-lg" type="password" name="password" placeholder="Password" onChange={handleChange} required/>
          {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
          <p className="text-sm text-gray-500 mb-4">Pulse uses data to personalize your experience. By signing up, you agree to our Terms and Privacy Policy. <Link to="/terms" className="text-indigo-500 underline">Terms</Link> and <Link to="/privacy" className="text-indigo-500 underline">Privacy Policy</Link>.</p>

          <div className="flex items-center mb-4">
            <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
            <span className="ml-2 text-lg">I Agree</span>
          </div>
          <button className="w-full bg-indigo-500 text-white text-xl py-3 rounded-full mt-4">Next</button>
        </form>
      )}

      {step === 2 && (
        <form onSubmit={handleSubmit}>
          <h2 className="text-2xl font-semibold mb-4">Create your account</h2>
          <p className="text-lg mb-2">Name: {userData.name}</p>
          <p className="text-lg mb-2">Email: {userData.email}</p>
          <p className="text-sm text-gray-500 mb-4">By signing up, you agree to the Terms of Service and Privacy Policy.</p>
          <button type="submit" className="w-full bg-indigo-500 text-white text-xl py-3 rounded-full mt-4">Sign Up</button>
        </form>
      )}
    </div>
  );
};

export default SignUp;