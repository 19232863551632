import React from "react";
import logo from './trianglai-icon.svg';

const UserProfile = ({ user }) => {
  return (
    <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        {/* <div className="absolute inset-0 bg-gradient-to-r from-indigo-400 to-indigo-500 shadow-lg sm:rounded-3xl"></div> */}
        <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
          <div className="max-w-md mx-auto">
            <div>
            <img src={user?.photoURL ?? logo} alt="profile" className="h-24 w-24 rounded-full mx-auto"/>               
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                <p><strong>Name: </strong>{user.displayName.split(" ")[0]}</p>
                <p><strong>Email: </strong>{user.email}</p>
              </div>
              <div className="pt-6 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                {/* <p><strong>About:</strong></p> */}
                {/* <p>{user.bio}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
