// src/index.js

// React and DOM imports
import React from 'react';
import { createRoot } from 'react-dom/client';

// Styling
import './index.css';

// Main App
import App from './App';  // This App will have Stripe's Elements wrapper integrated, as explained in (b)

// Web vitals
import reportWebVitals from './reportWebVitals';

// Firebase
import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDcsG5XxDoHdmmtWVtXCcIycYflloLwA_U",
  authDomain: "cuanto-set-waitlist.firebaseapp.com",
  projectId: "cuanto-set-waitlist",
  storageBucket: "cuanto-set-waitlist.appspot.com",
  messagingSenderId: "1060822109532",
  appId: "1:1060822109532:web:182b0a26d0642796240599",
  measurementId: "G-7H8D9VVYVQ"
};

// Initialize Firebase (only if it isn't already initialized)
if (!getApps().length) {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // Create a root.
root.render(<App />);

// Run reportWebVitals
reportWebVitals();